<template>
  <v-container fluid class="py-0">
    <v-row justify="center">
      <v-col class="pa-0">
        <v-card flat class="card-input">
          <v-card-text class="pa-2">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col justify="center" align="left" class="px-2 py-0">
                  <v-btn-toggle
                    depressed
                    tile
                    dark
                    mandatory
                    :value="inputCurrency"
                    @change="currencyChanged"
                  >
                    <v-btn
                      color="primary"
                      v-html="$t('sawCalc.inputCurrency.dollar')"
                      :x-small="$vuetify.breakpoint.mdAndDown"
                    ></v-btn>
                    <v-btn
                      color="primary"
                      v-html="$t('sawCalc.inputCurrency.euro')"
                      :x-small="$vuetify.breakpoint.mdAndDown"
                    ></v-btn>
                    <v-btn
                      color="primary"
                      v-html="$t('sawCalc.inputCurrency.pound')"
                      :x-small="$vuetify.breakpoint.mdAndDown"
                    ></v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "InputCurrency",

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["inputCurrency"]),
  },

  methods: {
    ...mapActions(["changeInputCurrency"]),
    currencyChanged(index) {
      this.changeInputCurrency(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  max-height: 30px;
  min-height: 23px;
}

.card-input {
  cursor: pointer;
}
</style>
