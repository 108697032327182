<template>
  <div>
    <v-card outlined>
      <v-form ref="selectForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-overlay :absolute="true" :value="machineOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>
              <v-col cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.manufacturerLabel')"
                  :items="manufacturers"
                  item-text="manufacturerName"
                  item-value="manufacturerID"
                  @change="manufacturerChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.modelLabel')"
                  :items="machinesFiltered"
                  item-text="model"
                  item-value="machineID"
                  @change="machineChanged"
                ></v-autocomplete>
              </v-col>

              <MachineSelectorDetails :item="item" :inputUOM="inputUOM" />

              <v-col
                cols="12"
                :sm="colsCount"
                class="px-1"
                align="right"
                :class="{ 'pt-0 pb-3': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.clearButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!machine.machineID"
                  @click="resetSelectForm()"
                ></v-btn>
              </v-col>
              <v-col
                v-if="user"
                cols="12"
                :sm="colsCount"
                class="px-1"
                align="right"
                :class="{ 'pt-0 pb-3': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.addButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!machine.machineID"
                  @click="addUserMachine()"
                ></v-btn>
              </v-col>
              <v-col
                cols="12"
                :sm="colsCount"
                class="px-1"
                align="right"
                :class="{ 'pt-0 pb-3': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.setMachine')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!machine.machineID"
                  @click="setMachine(machine)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import MachineSelectorDetails from "@/components/public/SawCalc/Selectors/Machine/MachineSelectorDetails";

import { mapGetters } from "vuex";

export default {
  name: "MachineSelect",

  components: {
    MachineSelectorDetails,
  },

  props: {
    manufacturers: {
      type: Array,
      required: true,
      default: () => [],
    },
    machines: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      manufacturer: null,
      machineOverlay: false,
    };
  },

  computed: {
    ...mapGetters(["inputUOM", "machine", "user", "resetInputs"]),
    machinesFiltered() {
      if (this.manufacturer) {
        const list = this.machines.filter(
          (item) => item.manufacturerID == this.manufacturer.manufacturerID
        );

        return list;
      }
      return [];
    },
    item() {
      const item = this.machine;
      return item;
    },
    colsCount() {
      if (this.user) {
        return 4;
      } else {
        return 6;
      }
    },
  },

  methods: {
    manufacturerChanged(id) {
      this.manufacturer = this.manufacturers.find(
        ({ manufacturerID }) => manufacturerID === id
      );

      this.resetSelect(false);
    },
    machineChanged(id) {
      this.machineOverlay = true;

      const machine = this.machines.find(({ machineID }) => machineID === id);
      machine.manufacturerName = this.manufacturer.manufacturerName;

      this.$store.dispatch("getMachine", machine).then(
        (response) => {
          this.machineOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.machineOverlay = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    setMachine(machine) {
      if (!machine) {
        return;
      }

      this.$store.dispatch("setInputMachine", machine);

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputMachine.machine")}: ${
          machine.manufacturerName
        }/${machine.model} ${this.$t("sawCalc.inputMachine.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    resetSelectForm() {
      this.resetSelect(true);
      this.$refs.selectForm.reset();
    },
    resetSelect(all) {
      this.$store.dispatch("setMachine", null);

      if (all) {
        this.$store.dispatch("setInputMachine", null);
      }
    },
    addUserMachine() {
      this.$store.dispatch("addUserMachine", this.machine).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    close() {
      this.$store.dispatch("showMachineSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      if (this.$refs.selectForm) {
        this.$refs.selectForm.reset();
      }
    }
  }
};
</script>
