<template>
  <v-container fluid class="py-0">
    <v-row justify="center">
      <v-col class="pa-0 pt-1 pl-1">
        <v-card flat :max-width="$vuetify.breakpoint.xs ? 390 : 320">
          <v-card-text class="pa-1 pt-0 pb-0">
            <v-container
              fluid
              class="pa-1 pt-0"
              :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
            >
              <v-row>
                <v-col class="px-2 py-0 card-title">
                  <v-row>
                    <v-col
                      v-if="$vuetify.breakpoint.smAndUp"
                      :cols="$vuetify.breakpoint.xs ? 7 : 12"
                      class="pa-0 pl-3"
                    >
                      <span
                        v-html="
                          $t(
                            'sawCalc.recommendation.title[' +
                              recommendationIndex +
                              ']'
                          )
                        "
                        class="pr-2 primary--text"
                        :class="{
                          'secondary--text': customized,
                        }"
                      />
                      <template>
                        <v-icon
                          v-for="i in rating"
                          color="yellow darken-2"
                          :key="`${i}-recommendationIndex`"
                          class="px-0 pb-1"
                          >mdi-star</v-icon
                        >
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-2 pt-2 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.recommendation.blade')"
                /></v-col>
              </v-row>
              <v-row>
                <v-col v-if="valid" class="px-4 py-0 card-text-description">{{
                  recommendation.bladeDescription
                }}</v-col>
                <v-col
                  v-else
                  class="px-4 py-0 card-text-description white--text"
                >
                  blade
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-2 pt-2 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.recommendation.specs')"
                /></v-col>
              </v-row>
              <v-row>
                <v-col v-if="valid" class="px-4 py-0 card-text-description">{{
                  getSpecs
                }}</v-col>
                <v-col
                  v-else
                  class="px-4 py-0 card-text-description white--text"
                >
                  specs
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-2 pt-1 pb-0">
                  <span class="card-text-caption"
                    ><span v-html="$t('sawCalc.recommendation.bandSpeed')"
                  /></span>
                  <span
                    class="pl-2 card-text-caption-uom"
                    :class="{ disabled: !reportEnabled }"
                    >{{ bandSpeedUOM }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="valid"
                  class="px-4 py-0 card-text-description-bold"
                  >{{ getBandSpeed }}</v-col
                >
                <v-col
                  v-else
                  class="px-4 py-0 card-text-description-bold white--text"
                >
                  bandSpeed
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-2 pt-1 pb-0">
                  <span class="card-text-caption"
                    ><span v-html="$t('sawCalc.recommendation.feedRate')"
                  /></span>
                  <span
                    class="pl-2 card-text-caption-uom"
                    :class="{ disabled: !reportEnabled }"
                    >{{ feedRateUOM }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="valid"
                  class="px-4 py-0 card-text-description-bold"
                  >{{ getFeedRate }}</v-col
                >
                <v-col
                  v-else
                  class="px-4 py-0 card-text-description-bold white--text"
                >
                  feedRate
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-2 pt-1 pb-0">
                  <span class="card-text-caption"
                    ><span v-html="$t('sawCalc.recommendation.chipLoad')"
                  /></span>
                  <span
                    class="pl-2 card-text-caption-uom"
                    :class="{ disabled: !reportEnabled }"
                    >{{ chipLoadUOM }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="valid" class="px-4 py-0 card-text-description">{{
                  getChipLoad
                }}</v-col>
                <v-col
                  v-else
                  class="px-4 py-0 card-text-description white--text"
                >
                  chipLoad
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-2 pt-1 pb-0">
                  <span class="card-text-caption"
                    ><span v-html="$t('sawCalc.recommendation.cuttingRate')"
                  /></span>
                  <span
                    class="pl-2 card-text-caption-uom"
                    :class="{ disabled: !reportEnabled }"
                    >{{ cuttingRateUOM }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="valid" class="px-4 py-0 card-text-description">{{
                  getCuttingRate
                }}</v-col>
                <v-col
                  v-else
                  class="px-4 py-0 card-text-description white--text"
                >
                  cuttingRate
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-2 pt-1 pb-0">
                  <span class="card-text-caption"
                    ><span v-html="$t('sawCalc.recommendation.cutTime')"
                  /></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="valid" class="px-4 py-0 card-text-description">{{
                  recommendation.cutTimeDescription
                }}</v-col>
                <v-col
                  v-else
                  class="px-4 py-0 card-text-description white--text"
                >
                  cutTime
                </v-col>
              </v-row>

              <v-row class="pt-2">
                <v-col
                  v-if="user && !user.admin"
                  cols="12"
                  sm="6"
                  class="pa-0 pl-2 pt-3"
                  :class="{ 'pb-5': $vuetify.breakpoint.smAndUp }"
                >
                  <v-btn
                    block
                    depressed
                    tile
                    v-html="$t('sawCalc.recommendation.save')"
                    color="primary"
                    :x-small="$vuetify.breakpoint.mdAndDown"
                    :disabled="!reportEnabled || !user"
                    @click="createUserRecommendation()"
                  >
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="pa-0 pl-2 pt-3"
                  :class="{ 'pb-5': $vuetify.breakpoint.smAndUp }"
                >
                  <v-btn
                    block
                    depressed
                    tile
                    v-html="$t('sawCalc.recommendation.customize')"
                    color="primary"
                    :x-small="$vuetify.breakpoint.mdAndDown"
                    :disabled="
                      !valid ||
                        selectedRecommendationForCustom == recommendationIndex
                    "
                    @click="customize(recommendationIndex)"
                  ></v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0 px-2 ma-0">
                  <CostCalculation
                    :recommendation="recommendation"
                    :recommendationIndex="recommendationIndex"
                    :costCalculationOriginal="costCalculation"
                    :reset="costCalculationReset"
                    :update="costCalculationUpdate"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CostCalculation from "@/components/public/SawCalc/CostCalculation";
import { mapGetters } from "vuex";

export default {
  name: "Recommendation",

  components: {
    CostCalculation,
  },

  props: {
    recommendationIndex: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      reportEnabled: false,
      costCalculationReset: 0,
      costCalculationUpdate: 0,
    };
  },

  computed: {
    ...mapGetters([
      "bandSpeedUOM",
      "feedRateUOM",
      "chipLoadUOM",
      "cuttingRateUOM",
      "recommendations",
      "costCalculations",
      "user",
      "recommendationUpdated",
      "inputUOM",
      "selectedRecommendationForCustom",
    ]),
    metric() {
      return this.inputUOM > 0;
    },
    recommendation() {
      return this.recommendations[this.recommendationIndex];
    },
    customized() { 
        
        try { 
            window.localStorage.setItem("recommendationIndex", JSON.stringify(this.recommendationIndex));
            let masterValues = window.localStorage.getItem("recommendationDataVal");
            if (masterValues ==null) {
                let dataValues = [];
                this.recommendations.forEach((elem, index) => {
                    if (Object.keys(elem).length > 0) {
                        let obj = JSON.parse(JSON.stringify(elem));
                        if (obj.bandSpeedImperial !==undefined) {
                            let object = {index: index, bandSpeedImperial : obj.bandSpeedImperial, bandSpeedMetric : obj.bandSpeedMetric , feedRateImperial : obj.feedRateImperial, feedRateMetric : obj.feedRateMetric , cuttingRateImperial : obj.cuttingRateImperial , cuttingRateMetric : obj.cuttingRateMetric, cutTime : obj.cutTime};
                         
                            if (Object.keys(object).length > 2) {
                                dataValues.push(object)
                            }
                        }  
                    }  
                });
                if (dataValues.length >0) {
                    window.localStorage.setItem("recommendationDataVal", JSON.stringify(dataValues));
                }
            }

            //setting new customization
            window.localStorage.setItem("initialCustomizationVal", JSON.stringify(this.recommendation));
        }catch(error){
          //console.log(error)
            // eslint-disable-next-line
        }
      
        if (this.recommendation != undefined && this.recommendation != null) {
            return this.recommendation.customized
        } else {
            return false
        }
    },
    getSpecs() {
      if (this.metric) {
        return this.recommendation.specsMetric;
      } else {
        return this.recommendation.specsImperial;
      }
    },
    getBandSpeed() {
      if (this.metric) {
        return this.recommendation.bandSpeedMetric;
      } else {
        return this.recommendation.bandSpeedImperial;
      }
    },
    getFeedRate() {
      if (this.metric) {
        return this.recommendation.feedRateMetric;
      } else {
        return this.recommendation.feedRateImperial;
      }
    },
    getChipLoad() {
      if (this.metric) {
        return this.recommendation.chipLoadMetric;
      } else {
        return this.recommendation.chipLoadImperial;
      }
    },
    getCuttingRate() {
      if (this.metric) {
        return this.recommendation.cuttingRateMetric;
      } else {
        return this.recommendation.cuttingRateImperial;
      }
    },
    valid() {
      if (this.metric) {
        return (
          this.recommendation &&
          this.recommendation.bladeDescription &&
          this.recommendation.specsMetric &&
          this.recommendation.bandSpeedMetric &&
          this.recommendation.feedRateMetric &&
          this.recommendation.chipLoadMetric &&
          this.recommendation.cuttingRateMetric &&
          this.recommendation.cutTimeDescription
        );
      } else {
        return (
          this.recommendation &&
          this.recommendation.bladeDescription &&
          this.recommendation.specsImperial &&
          this.recommendation.bandSpeedImperial &&
          this.recommendation.feedRateImperial &&
          this.recommendation.chipLoadImperial &&
          this.recommendation.cuttingRateImperial &&
          this.recommendation.cutTimeDescription
        );
      }
    },
    costCalculation() {
      return this.costCalculations[this.recommendationIndex];
    },
    rating() {
      return this.recommendationIndex + 3;
    },
  },

  methods: {
    customize(recommendationIndex) {
      this.$store.dispatch("customize", recommendationIndex)
        .then(()=>{
          this.costCalculationUpdate += 1;
        })
    },
    createUserRecommendation() {
      let custom;

      if (this.recommendation.custom) {
        custom = this.recommendation.custom;
        delete this.recommendation.custom;
      }
      else {
        custom = {
          bandSpeedOverride: 100,
          feedRateSpeedOverride: 100,
          productTypeOverride: "",
          tpiOverride: "",
        }
      }
      
      const form = {
        recommendation: this.recommendation,
        custom: custom,
        costCalculation: this.costCalculation,
      };

      this.$store.dispatch("createUserRecommendation", form).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    validateControls() {
      const enabled =
        this.recommendation != null && this.recommendation.cutTime > 0;

      this.reportEnabled = enabled;
    },
    updateCostCalculation() {
      if (
        this.recommendation == null ||
        this.recommendation.cutTime == undefined ||
        this.recommendation.cutTime == 0
      ) {
        this.costCalculationReset += 1;
      }
    },
  },

  watch: {
    recommendationUpdated() {
      this.validateControls();
      this.updateCostCalculation();
    },
  },

  created() {
    this.validateControls();
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  max-height: 30px;
  min-height: 23px;
}
.theme--light.v-card.v-card--outlined {
  border-width: 0px;
}
.disabled {
  color: map-get($theme, footer-link-color);
}
</style>
