<template>
  <div>
    <v-container v-if="items.length == 0" style="min-height: 225px;">
      <v-row>
        <v-col>
          <span>{{ $t("sawCalc.inputMachine.noMachines") }}</span>
        </v-col>
      </v-row>
    </v-container>
    <v-card v-if="items.length > 0" outlined>
      <v-card-text class="pa-0">
        <v-container class="py-0">
          <v-row>
            <v-col class="pa-0">
              <v-list
                v-for="(item, index) in items"
                :key="item.title"
                two-line
                flat
              >
                <v-list-item>
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-container class="pa-0">
                          <v-row>
                            <v-col cols="12" class="pa-0 pl-3">
                              <span
                                class="card-title"
                                v-text="item.machineName"
                              ></span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--primary">
                        <v-container class="pa-1">
                          <v-row>
                            <v-col cols="6" class="px-2 py-0">
                              <v-col class="pa-0">
                                <div class="card-text-caption">
                                  {{
                                    $t("sawCalc.inputMachine.manufacturerLabel")
                                  }}
                                </div>
                                <div class="pl-2 card-text-description">
                                  {{ item.manufacturerName }}
                                </div>
                              </v-col>
                            </v-col>
                            <v-col cols="6" class="px-2 py-0">
                              <v-col class="pa-0">
                                <div class="card-text-caption">
                                  {{ $t("sawCalc.inputMachine.modelLabel") }}
                                </div>
                                <div class="pl-2 card-text-description">
                                  {{ item.model }}
                                </div>
                              </v-col>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-list-item-subtitle>
                      <v-card outlined class="pa-0">
                        <v-expansion-panels
                          v-model="details[index]"
                          flat
                          class="pa-0"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              class="primary--text"
                              @click="getUserMachine(item)"
                              >{{
                                $t("sawCalc.inputMachine.details")
                              }}</v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <v-row>
                                <v-overlay
                                  :absolute="true"
                                  :value="
                                    userMachineOverlay(item.userMachineID)
                                  "
                                >
                                  <v-progress-circular
                                    indeterminate
                                    size="32"
                                  ></v-progress-circular>
                                </v-overlay>

                                <MachineSelectorDetails
                                  :item="item"
                                  :inputUOM="inputUOM"
                                />
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-row class="px-5 pb-3">
                  <v-col
                    cols="12"
                    sm="6"
                    class="pa-0 px-1"
                    :class="{ 'pt-0 pb-3': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-btn
                      depressed
                      tile
                      v-html="$t('sawCalc.inputMachine.removeMachine')"
                      class="primary white--text full-width"
                      @click="removeUserMachineConfirm(item)"
                    ></v-btn>
                  </v-col>

                  <v-col cols="12" sm="6" class="pa-0 px-1">
                    <v-btn
                      depressed
                      tile
                      v-html="$t('sawCalc.inputMachine.setMachine')"
                      class="primary white--text full-width"
                      @click="setUserMachine(item)"
                    ></v-btn>
                  </v-col>
                </v-row>

                <v-divider
                  v-if="index + 1 < items.length"
                  :key="index"
                ></v-divider>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmDialog.show" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{
          $t("sawCalc.inputMachine.deleteMachineTitle")
        }}</v-card-title>

        <v-card-text>
          {{ $t("sawCalc.inputMachine.deleteMachineMessage") }}
        </v-card-text>

        <v-card-actions>
          <v-col cols="6" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('sawCalc.inputMachine.noButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="cancelRemoveUserMachine()"
            ></v-btn>
          </v-col>
          <v-col cols="6" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('sawCalc.inputMachine.yesButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="removeUserMachine()"
            ></v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MachineSelectorDetails from "@/components/public/SawCalc/Selectors/Machine/MachineSelectorDetails";

import { mapGetters } from "vuex";

export default {
  name: "UserMachine",

  components: {
    MachineSelectorDetails,
  },

  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      details: [],
      confirmDialog: {
        show: false,
        item: null,
      },
      userMachineOverlays: [],
    };
  },

  computed: {
    ...mapGetters(["inputUOM"]),
  },

  methods: {
    getUserMachine(item) {
      if (!item || item.defined) {
        return;
      }

      this.setUserMachineOverlay(item, true);

      this.$store.dispatch("getUserMachine", item).then(
        (response) => {
          this.setUserMachineOverlay(item, false);

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.setUserMachineOverlay(item, false);

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    setUserMachine(item) {
      if (!item) {
        return;
      }

      this.$store.dispatch("setInputMachine", item);

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputMachine.machine")}: ${
          item.machineName
        } ${this.$t("sawCalc.inputMachine.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    userMachineSetup(item) {
      return this.inputMachine.machineID != item.userMachineID;
    },
    userMachineOverlay(id) {
      return this.userMachineOverlays.includes(id);
    },
    setUserMachineOverlay(item, show) {
      if (show) {
        this.userMachineOverlays.push(item.userMachineID);
      } else {
        const index = this.userMachineOverlays.indexOf(item.userMachineID);
        if (index > -1) {
          this.userMachineOverlays.splice(index, 1);
        }
      }
    },
    removeUserMachineProceed(item) {
      this.$store.dispatch("removeUserMachine", item).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$store.dispatch("setInputMachine", null);
        },
        (error) => {
          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    removeUserMachineConfirm(item) {
      this.confirmDialog.item = item;
      this.confirmDialog.show = true;
    },
    removeUserMachine() {
      this.confirmDialog.show = false;
      this.removeUserMachineProceed(this.confirmDialog.item);
    },
    cancelRemoveUserMachine() {
      this.confirmDialog.show = false;
    },
    close() {
      this.$store.dispatch("showMachineSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    items(val) {
      for (let i = 0; i < val.length; i++) {
        this.details[i] = [];
      }
    },
  },
};
</script>
