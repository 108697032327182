<template>
  <v-container
    class="pa-0 px-2"
    :class="{ 'px-6': $vuetify.breakpoint.mdAndUp }"
  >
    <v-row>
      <v-col cols="12" sm="8">
        <v-card flat>
          <v-card-title class="pa-0 pb-2">
            <span class="card-text-caption">{{
              $t("sawCalc.inputMaterial.scaleLabel")
            }}</span>
          </v-card-title>
          <v-list dense class="pa-0 pb-2">
            <v-list-item-group
              v-model="scaleIndex"
              color="primary"
              class="pr-2"
            >
              <v-list-item
                v-for="(item, i) in scales"
                :key="i"
                :disabled="scaleDisabled"
                @click="setScale(item)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.description"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="py-1 pl-0"
        :class="{ 'py-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              hide-details
              style="width: 70px; color: yellow;"
              :label="$t('sawCalc.inputMaterial.hardnessLabel')"
              v-model="item.hardness"
              :disabled="!valid"
              type="number"
              :rules="inputRules"
              @change="hardnessChanged"
              @keydown.enter.prevent=""
            >
            </v-text-field>
          </v-col>
          <v-col v-if="item.htQuenched" cols="12" class="pa-0">
            <span class="secondary--text hardness-adjusted">{{
              $t("sawCalc.inputMaterial.hardnessAdjustedLabel")
            }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pl-3">
      <v-col cols="12" sm="12" class="pa-0">
        <div class="card-text-caption">
          {{ $t("sawCalc.inputMaterial.surfaceConditionLabel") }}
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="pa-0">
        <v-switch
          class="pa-0"
          v-model="item.heavyScaleForged"
          :label="$t('sawCalc.inputMaterial.heavyScaleForgedLabel')"
          :disabled="!valid"
          @change="heavyScaleForgedChanged"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row class="pl-3">
      <v-col cols="12" sm="12" class="pa-0">
        <div class="card-text-caption">
          {{ $t("sawCalc.inputMaterial.materialConditionLabel") }}
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="pa-0">
        <v-switch
          class="pa-0"
          v-model="item.htQuenched"
          :label="$t('sawCalc.inputMaterial.htQuenchedLabel')"
          :disabled="!valid"
          @change="htQuenchedChanged"
        ></v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MaterialAjustments",

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    scales: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      scaleIndex: null,
      inputRules: [
        (v) =>
          (!!v && this.validHardness) ||
          this.$t("sawCalc.inputMaterial.inputRulesHardness"),
      ],
    };
  },

  computed: {
    ...mapGetters(["hbHardnessMin", "hbHardnessMax", "hrcHardnessMin", "hrcHardnessMax", "hbHardnessMinConvertable"]),
    valid() {
      if (
        this.item == undefined ||
        this.item == null ||
        this.item.materialID == undefined ||
        this.item.materialID == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    validHardness() {
      const scale = this.item.scale

      if (scale == undefined) {
        return false
      }

      if (scale.id == 1) {
        return this.item.hardness >= this.hbHardnessMin && this.item.hardness <= this.hbHardnessMax
      }
      else if (scale.id == 2) {
        return this.item.hardness >= this.hrcHardnessMin && this.item.hardness <= this.hrcHardnessMax
      } else {
        return false
      }
    },
    scaleDisabled(){
      const scale = this.item.scale

      if (scale == undefined) {
        return true
      }

      if (scale.id == 1) {
        return this.item.hardness < this.hbHardnessMinConvertable
      }
      else if (scale.id == 2) {
        return this.item.hardness < this.hrcHardnessMin
      } else {
        return false
      }
    }
  },

  methods: {
    setScaleIndex() {
      let scaleIndex = null;

      if (this.item && this.item.scale) {
        scaleIndex = this.item.scale.id - 1;
      }

      this.scaleIndex = scaleIndex;
    },
    setScale(item) {
      if (!item || !this.item.scale || item.id == this.item.scale.id) {
        return;
      }

      this.$store.dispatch("setScale", {
        scale: item,
        hardness: this.item.hardness,
        hardnessCalculated: this.item.hardnessCalculated,
      });
    },
    hardnessChanged(hardness) {
      const scale = this.item.scale

      if (scale == undefined) {
        return
      }

      let newHardness = hardness

      if (scale.id == 1) {
        if (hardness < this.hbHardnessMin) {
          newHardness = this.hbHardnessMin
        }
        else if (hardness > this.hbHardnessMax) {
          newHardness = this.hbHardnessMax
        }
      }
      else if (scale.id == 2) {
        if (hardness < this.hrcHardnessMin) {
          newHardness = this.hrcHardnessMin
        }
        else if (hardness > this.hrcHardnessMax) {
          newHardness = this.hrcHardnessMax
        }
      }

      if (newHardness != hardness) {
        this.$nextTick(() => {
          this.item.hardness = newHardness
        })
      }

      this.$store.dispatch("setHardness", hardness);
    },
    heavyScaleForgedChanged(item) {
      this.$store.dispatch("setHeavyScaleForged", item);
    },
    htQuenchedChanged(item) {
      const scale = this.scales.find((item) => item.id == 1);

      if (scale != undefined && scale != null) {
        this.setScale(scale);
        this.scaleIndex = scale.id - 1;
      }

      if (item) {
        this.hardnessChanged(this.item.defaultHBHardness * 1.5);
      } else {
        this.hardnessChanged(this.item.defaultHBHardness);
      }

      this.$store.dispatch("setHTQuenched", item);
    },
  },

  watch: {
    item(val) {
      if (val && val.scale) {
        this.setScaleIndex();
      }
    },
  },

  mounted() {
    this.setScaleIndex();
  },
};
</script>

<style lang="scss" scoped>
.v-list .v-list-item--active {
  border-left: 3px solid map-get($theme, secondary-color);
}
.v-input--selection-controls {
  margin-top: 5px;
}
.hardness-adjusted {
  font-size: 0.8em;
  font-weight: bold;
}
</style>
