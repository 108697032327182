<template>
    <v-container class="pa-0 pl-3">
        <v-row>
            <v-col cols="4" class="px-2 pt-0">
                <div class="card-text-caption">
                    {{ $t('sawCalc.inputMachine.bladeWidthLabel') }}
                </div>
                <div v-if="inputUOM==0" class="pl-2 card-text-description">
                    {{ item.bladeWidthImperialDesc }}
                </div>
                <div v-else class="pl-2 card-text-description">
                    {{ item.bladeWidthMetricDesc }}
                </div>
            </v-col>
            <v-col cols="4" class="px-2 pt-0">
                <div class="card-text-caption">
                    {{ $t('sawCalc.inputMachine.bladeThicknessLabel') }}
                </div>
                <div v-if="inputUOM==0" class="pl-2 card-text-description">
                    {{ item.bladeThicknessImperialDesc }}
                </div>
                <div v-else class="pl-2 card-text-description">
                    {{ item.bladeThicknessMetricDesc }}
                </div>
            </v-col>
            <v-col cols="4" class="px-2 pt-0">
                <div class="card-text-caption">
                    {{ $t('sawCalc.inputMachine.bladeLengthLabel') }}
                </div>
                <div v-if="inputUOM==0" class="pl-2 card-text-description">
                    {{ item.bladeLengthImperialDesc }}
                </div>
                <div v-else class="pl-2 card-text-description">
                    {{ item.bladeLengthMetricDesc }}
                </div>
            </v-col>
            <v-col cols="4" class="px-2 pt-0">
                <div class="card-text-caption">
                    {{ $t('sawCalc.inputMachine.machineOrientationLabel') }}
                </div>
                <div class="pl-2 card-text-description">
                    {{ item.machineOrientationDesc }}
                </div>
            </v-col>
            <v-col cols="4" class="px-2 pt-0">
                <div class="card-text-caption">
                    {{ $t('sawCalc.inputMachine.scissorPivotLabel') }}
                </div>
                <div class="pl-2 card-text-description">
                    {{ item.scissorPivotDesc }}
                </div>
            </v-col>
            <v-col cols="4" class="px-2 pt-0">
                <div class="card-text-caption">
                    {{ $t('sawCalc.inputMachine.cantedHeadLabel') }}
                </div>
                <div class="pl-2 card-text-description">
                    {{ item.cantedHeadDesc }}
                </div>
            </v-col>
            <v-col cols="4" offset="4" class="px-2 pt-0">
                <div class="card-text-caption">
                    {{ $t('sawCalc.inputMachine.bandSpeedMinLabel') }}
                </div>
                <div v-if="inputUOM==0" class="pl-2 card-text-description">
                    {{ item.bandSpeedMinImperialDesc }}
                </div>
                <div v-else class="pl-2 card-text-description">
                    {{ item.bandSpeedMinMetricDesc }}
                </div>
            </v-col>
            <v-col cols="4" class="px-2 pt-0">
                <div class="card-text-caption">
                    {{ $t('sawCalc.inputMachine.bandSpeedMaxLabel') }}
                </div>
                <div v-if="inputUOM==0" class="pl-2 card-text-description">
                    {{ item.bandSpeedMaxImperialDesc }}
                </div>
                <div v-else class="pl-2 card-text-description">
                    {{ item.bandSpeedMaxMetricDesc }}
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
export default {
  name: "MachineSelector",

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    inputUOM: {
      type: Number,
      required: true,
      default: () => 1
    }
  }
};
</script>