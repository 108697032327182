var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"},on:{"click":_vm.showSelector}},[_c('v-col',{staticClass:"pa-0"},[_c('v-card',{class:{
          'card-input': _vm.selectedRecommendationForCustom == null,
          'card-disabled': _vm.selectedRecommendationForCustom != null,
        },attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-2"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"px-2 py-0",class:{
                  'title-ready': _vm.inputDimensions.shape != null,
                  'title-not-ready': _vm.inputDimensions.shape == null,
                  'card-disabled': _vm.selectedRecommendationForCustom != null,
                }},[_c('h4',[_vm._v(_vm._s(_vm.$t("sawCalc.inputDimensions.title")))])])],1),(_vm.dimensionsReady)?_c('v-row',[_c('v-col',{staticClass:"px-2 pt-1 pb-0 card-text-caption"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('sawCalc.inputDimensions.shape'))}})])],1):_vm._e(),(_vm.dimensionsReady)?_c('v-row',[_c('v-col',{staticClass:"px-4 py-0 card-text-description",class:{
                  'card-disabled': _vm.selectedRecommendationForCustom != null,
                }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getShapeTitle)}})])],1):_vm._e(),(_vm.dimensionsReady)?_c('v-row',[_c('v-col',{staticClass:"px-2 pt-1 pb-0 card-text-caption"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('sawCalc.inputDimensions.dimensions'))}})])],1):_vm._e(),(_vm.dimensionsReady)?_c('v-row',[_c('v-col',{staticClass:"px-4 py-0 card-text-description",class:{
                  'card-disabled': _vm.selectedRecommendationForCustom != null,
                }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getDescription)}})])],1):_c('v-row',[_c('v-col',{staticClass:"px-2 pt-1 pb-0 card-text-error"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('sawCalc.inputDimensions.errorNotLoaded'))}})])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }