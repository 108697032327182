<template>
  <v-container fluid class="py-0">
    <v-row justify="center" @click="showSelector">
      <v-col class="pa-0">
        <v-card flat 
          :class="{
            'card-input': selectedRecommendationForCustom == null,
            'card-disabled': selectedRecommendationForCustom != null,
          }">
          <v-card-text class="pa-2">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col
                  class="px-2 py-0" 
                  :class="{
                    'title-ready': inputMachine.manufacturerName != null,
                    'title-not-ready': inputMachine.manufacturerName == null,
                    'card-disabled': selectedRecommendationForCustom != null,
                  }"
                  ><h4>{{ $t("sawCalc.inputMachine.title") }}</h4></v-col
                >
              </v-row>
              <v-row v-if="machinesReady && inputMachine.machineName">
                <v-col class="px-2 pt-1 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.inputMachine.machineNameLabel')"
                /></v-col>
              </v-row>
              <v-row v-if="machinesReady && inputMachine.machineName">
                <v-col 
                  class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="inputMachine.machineName"
                /></v-col>
              </v-row>
              <v-row v-if="machinesReady && inputMachine.machineName">
                <v-col 
                  class="px-2 pt-1 pb-0 card-text-caption"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="$t('sawCalc.inputMachine.manufacturerLabel')"
                /></v-col>
              </v-row>
              <v-row v-if="machinesReady && inputMachine.machineName">
                <v-col 
                  class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="inputMachine.manufacturerName" />
                </v-col>
              </v-row>

              <v-row v-if="machinesReady && !inputMachine.machineName">
                <v-col class="px-2 pt-1 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.inputMachine.manufacturerLabel')"
                /></v-col>
              </v-row>
              <v-row v-if="machinesReady && !inputMachine.machineName">
                <v-col 
                class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="inputMachine.manufacturerName" />
                </v-col>
              </v-row>
              <v-row v-if="machinesReady && !inputMachine.machineName">
                <v-col 
                  class="px-2 pt-1 pb-0 card-text-caption"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }"
                  ><span v-html="$t('sawCalc.inputMachine.modelLabel')"
                /></v-col>
              </v-row>
              <v-row v-if="machinesReady && !inputMachine.machineName">
                <v-col 
                  class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="inputMachine.model"
                /></v-col>
              </v-row>
              <v-row v-if="!machinesReady">
                <v-col class="px-2 pt-1 pb-0 card-text-error"
                  ><span v-html="$t('sawCalc.inputMachine.errorNotLoaded')"
                /></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InputMachine",

  computed: {
    ...mapGetters([
      "inputMachine",
      "machinesReady",
      "selectedRecommendationForCustom",
    ]),
  },

  methods: {
    showSelector() {
      if (this.machinesReady && this.selectedRecommendationForCustom == null) {
        this.$store.dispatch("showMachineSelectorDialog", true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-input {
  cursor: pointer;
}
.card-disabled {
  color: map-get($theme, footer-link-color);
  cursor: not-allowed;
}
</style>
