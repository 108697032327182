<template>
  <v-form ref="form">
    <v-container class="pa-0">
      <v-row v-if="!configurationAvailable" align="center" justify="center">
        <v-col align="center" justify="center">
          {{ $t("sawCalc.inputVising.configurationNotAvailable") }}
        </v-col>
      </v-row>
      <v-row v-else-if="inputDimensions && inputDimensions.shape">
        <v-col cols="12" sm="5" class="py-0">
          <v-container class="pa-0 px-3">
            <v-row align="center" justify="center">
              <v-col cols="12">
                {{ $t("sawCalc.inputVising.specifyQuantityArrangement") }}
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('sawCalc.inputVising.highLabel')"
                  v-model="vising.quantity.high"
                  :rules="inputRulesHigh"
                  type="number"
                  autofocus
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :label="$t('sawCalc.inputVising.wideLabel')"
                  v-model="vising.quantity.wide"
                  :rules="inputRulesWide"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-tabs
                  v-model="selectedTab"
                  fixed-tabs
                  background-color="transparent"
                  slider-color="primary"
                  slider-size="2"
                  vertical
                  @change="arrangementChanged()"
                >
                  <v-tab
                    v-for="(item, i) in tabs"
                    :key="`${i}-tab`"
                    class="selector-tab"
                    :class="{
                      'primary--text': !tabActive(item.id),
                      'secondary--text': tabActive(item.id),
                    }"
                  >
                    <v-container class="pa-0">
                      <v-row align="center" justify="center">
                        <v-col>{{ getArrangementTitle(item) }}</v-col>
                        <v-col><img :src="item.img" width="25"/></v-col>
                      </v-row>
                    </v-container>
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" sm="7" align="center" justify="center" class="py-0">
          <v-container class="pa-0">
            <v-row>
              <v-col class="pb-0">
                {{ $t("sawCalc.inputVising.exampleViseSetup") }}
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col class="pt-0">
                <ViseSetup :img="img" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else align="center" justify="center">
        <v-col align="center" justify="center">
          {{ $t("sawCalc.inputVising.dimensionsRequired") }}
        </v-col>
      </v-row>
      <v-row class="pa-0 px-4">
        <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
          <v-btn
            depressed
            tile
            v-html="$t('sawCalc.inputVising.clearButton')"
            class="primary white--text full-width"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="!dirty"
            @click="resetForm()"
          ></v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
          <v-btn
            depressed
            tile
            v-html="$t('sawCalc.inputVising.setVising')"
            class="primary white--text full-width"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="!valid"
            @click="setVising(vising)"
          ></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ViseSetup from "@/components/public/SawCalc/Selectors/Vising/ViseSetup";

import { mapGetters } from "vuex";

export default {
  name: "Multiple",

  components: {
    ViseSetup,
  },

  data() {
    return {
      vising: {
        quantity: {
          high: null,
          wide: null,
          description: null,
        },
        arrangement: {
          index: null,
          description: null,
        },
      },
      visingDefault: {
        quantity: {
          high: null,
          wide: null,
          description: "multiple",
        },
        arrangement: {
          index: 1,
          description: "nested",
        },
      },
      selectedTab: 0,
      inputRulesHigh: [
        (v) =>
          (!!v && v.length > 0) || this.$t("sawCalc.inputVising.inputRules"),
        (v) =>
          (!!v && this.validHigh) ||
          this.$t("sawCalc.inputVising.inputRulesNumber"),
      ],
      inputRulesWide: [
        (v) =>
          (!!v && v.length > 0) || this.$t("sawCalc.inputVising.inputRules"),
        (v) =>
          (!!v && this.validWide) ||
          this.$t("sawCalc.inputVising.inputRulesNumber"),
      ],
    };
  },

  computed: {
    ...mapGetters(["inputUOM", "inputDimensions", "resetInputs"]),
    tabs() {
      const tabs = [
        {
          id: 0,
          img: "/images/Vising/nested.png",
          shape: {
            id: 0,
            title: "nested",
          },
        },
        {
          id: 1,
          img: "/images/Vising/stacked.png",
          shape: {
            id: 1,
            title: "stacked",
          },
        },
      ];

      const shape = this.inputDimensions.shape.title;

      if (shape == "I-Beam") {
        return tabs.filter((item) => item.id == 1);
      } else {
        return tabs;
      }
    },
    dimenstionsDefined() {
      return this.inputDimensions && this.inputDimensions.shape;
    },
    dirty() {
      return JSON.stringify(this.vising) !== JSON.stringify(this.visingDefault);
    },
    valid() {
      if (!this.vising) {
        return false;
      }

      return this.validHigh && this.validWide;
    },
    validHigh() {
      if (!this.vising && this.vising.quantity) {
        return false;
      }

      const high = this.vising.quantity.high;

      return high && high > 0;
    },
    validWide() {
      if (!this.vising && this.vising.quantity) {
        return false;
      }

      const wide = this.vising.quantity.wide;

      return wide && wide > 0;
    },
    img() {
      if (!this.inputDimensions || !this.inputDimensions.shape) {
        return "";
      }

      const shapeTitle = this.inputDimensions.shape.title;

      if (shapeTitle == "solidRound") {
        return "/images/Vising/multiple-solid-round.png";
      } else if (shapeTitle == "roundTube") {
        return "/images/Vising/multiple-round-tube.png";
      } else if (shapeTitle == "solidRectangle") {
        return "/images/Vising/multiple-rectangle.png";
      } else if (shapeTitle == "rectangleTube") {
        return "/images/Vising/multiple-rectangle-tube.png";
      } else if (shapeTitle == "iBeam") {
        return "/images/Vising/multiple-ibeam.png";
      } else if (shapeTitle == "angleIron") {
        return "/images/Vising/multiple-angle-iron.png";
      } else if (shapeTitle == "channel") {
        return "/images/Vising/multiple-channel.png";
      } else {
        return "";
      }
    },
    configurationAvailable() {
      if (!this.inputDimensions || !this.inputDimensions.shape) {
        return false;
      }

      return true;
    },
  },

  methods: {
    setFormDefaults() {
      this.vising = JSON.parse(JSON.stringify(this.visingDefault));
      this.setSelectedTab(this.vising.arrangement.index);
    },
    setSelectedTab(tab) {
      this.selectedTab = tab;
    },
    getArrangementTitle(item){
      return this.$t(`sawCalc.inputVising.${item.shape.title.toLowerCase()}`)
    },
    arrangementChanged() {
      this.vising.arrangement.index = this.selectedTab;
    },
    tabActive(id) {
      return id == this.selectedTab;
    },
    setVising() {
      this.vising.arrangement.description =
        this.tabs[this.selectedTab].shape.title +
        " " +
        this.vising.quantity.high +
        " x " +
        this.vising.quantity.wide;

      this.$store.dispatch("setInputVising", {
        vising: this.vising,
        uom: this.inputUOM,
      });

      const shape = this.$t(`sawCalc.inputDimensions.${this.inputDimensions.shape.title}`)

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputVising.vising")} ${this.$t("sawCalc.inputVising.for" )}: 
          ${shape} ${this.$t("sawCalc.inputVising.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    resetForm() {
      this.$refs.form.reset();
      this.setFormDefaults();

      this.$store.dispatch("setInputVising", null);
    },
    close() {
      this.$store.dispatch("showVisingSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.setFormDefaults();
    },
  },

  mounted() {
    this.setFormDefaults();
  },
};
</script>
