<template>
  <v-form ref="form">
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12" align="center" justify="center" class="py-0">
          <v-container class="pa-0">
            <v-row
              v-if="inputDimensions && inputDimensions.shape"
              align="center"
              justify="center"
            >
              <v-col>
                <ViseSetup :img="img" />
              </v-col>
            </v-row>
            <v-row v-else align="center" justify="center">
              <v-col>
                {{ $t("sawCalc.inputVising.dimensionsRequired") }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="pa-0 px-4">
        <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
          <v-btn
            depressed
            tile
            v-html="$t('sawCalc.inputVising.clearButton')"
            class="primary white--text full-width"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="!inputVising || !inputVising.quantity"
            @click="resetForm()"
          ></v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
          <v-btn
            depressed
            tile
            v-html="$t('sawCalc.inputVising.setVising')"
            class="primary white--text full-width"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="!dimenstionsDefined"
            @click="setVising(vising)"
          ></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ViseSetup from "@/components/public/SawCalc/Selectors/Vising/ViseSetup";

import { mapGetters } from "vuex";

export default {
  name: "Single",

  components: {
    ViseSetup,
  },

  data() {
    return {
      vising: null,
    };
  },

  computed: {
    ...mapGetters(["inputUOM", "inputDimensions", "inputVising"]),
    dimenstionsDefined() {
      return this.inputDimensions && this.inputDimensions.shape;
    },
    img() {
      if (!this.inputDimensions || !this.inputDimensions.shape) {
        return "";
      }

      const shapeTitle = this.inputDimensions.shape.title;

      if (shapeTitle == "solidRound") {
        return "/images/Vising/single-solid-round.png";
      } else if (shapeTitle == "roundTube") {
        return "/images/Vising/single-round-tube.png";
      } else if (shapeTitle == "solidRectangle") {
        return "/images/Vising/single-rectangle.png";
      } else if (shapeTitle == "rectangleTube") {
        return "/images/Vising/single-rectangle-tube.png";
      } else if (shapeTitle == "iBeam") {
        return "/images/Vising/single-ibeam.png";
      } else if (shapeTitle == "angleIron") {
        return "/images/Vising/single-angle-iron.png";
      } else if (shapeTitle == "channel") {
        return "/images/Vising/single-channel.png";
      } else {
        return "";
      }
    },
  },

  methods: {
    setVising() {
      this.$store.dispatch("setInputVising", {
        vising: {
          quantity: {
            description: "single",
          },
          arrangement: {
            description: "",
          },
        },
        uom: this.inputUOM,
      });

      const shape = this.$t(`sawCalc.inputDimensions.${this.inputDimensions.shape.title}`)

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputVising.vising")} ${this.$t("sawCalc.inputVising.for" )}: 
          ${shape} ${this.$t("sawCalc.inputVising.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    resetForm() {
      this.$store.dispatch("setInputVising", null);
    },
    close() {
      this.$store.dispatch("showVisingSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },
};
</script>
