<template>
    <v-container class="pa-0">
        <v-row>
            <v-col cols="12" sm="8" class="pb-0">
                <v-container class="pa-0 px-3">
                    <v-row>
                        <v-col cols="12" sm="6" class="pa-0 pb-2">
                        <div class="card-text-caption">
                            {{ $t("sawCalc.inputMaterial.designationLabel") }}
                        </div>
                        <div class="pl-2 card-text-description">
                            {{ item.designation }}
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 pb-2">
                        <div class="card-text-caption">
                            {{ $t("sawCalc.inputMaterial.standardLabel") }}
                        </div>
                        <div class="pl-2 card-text-description">
                            {{ item.materialStandard }}
                        </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" class="pa-0 pb-2">
                        <div class="card-text-caption">
                            {{ $t("sawCalc.inputMaterial.machinabilityLabel") }}
                        </div>
                        <div class="pl-2 card-text-description">
                            {{ item.machinability }}
                        </div>
                        </v-col>
                        <v-col cols="12" sm="6" class="pa-0 pb-2">
                        <div class="card-text-caption">
                            {{ $t("sawCalc.inputMaterial.defaultHBHardnessLabel") }}
                        </div>
                        <div class="pl-2 card-text-description">
                            {{ item.defaultHBHardness }}
                        </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0 pb-2">
                        <div class="card-text-caption">
                            {{ $t("sawCalc.inputMaterial.unsCategoryLabel") }}
                        </div>
                        <div class="pl-2 card-text-description">
                            {{ item.unsCategory }}
                        </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0 pb-2">
                        <div class="card-text-caption">
                            {{ $t("sawCalc.inputMaterial.unsAlloyGroupLabel") }}
                        </div>
                        <div class="pl-2 card-text-description">
                            {{ item.unsAlloyGroup }}
                        </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0">
                        <div class="card-text-caption">
                            {{ $t("sawCalc.inputMaterial.unsGeneralDescriptionLabel") }}
                        </div>
                        <div class="pl-2 card-text-description">
                            {{ item.unsGeneralDescription }}
                        </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
                <v-row class="pl-2">
                <div class="card-text-caption">
                    {{ $t("sawCalc.inputMaterial.chemicalCompositionLabel") }}
                </div>
                <v-container
                    v-if="item.chemicalComposition"
                    class="pa-3 pl-6 pt-1 pb-0 chemical-composition"
                >
                    <v-row class="pa-0 pb-1 chemical-composition-header">
                    <v-col cols="4" class="pa-0">
                        <span>Elem.</span>
                    </v-col>
                    <v-col cols="4" class="pa-0 pr-6" align="right">
                        <span>Min %</span>
                    </v-col>
                    <v-col cols="4" class="pa-0 pr-6" align="right">
                        <span>Max %</span>
                    </v-col>
                    </v-row>
                    <v-row
                        v-for="(itm, i) in item.chemicalComposition"
                        :key="`${i}`"
                        class="pa-0"
                        >
                        <v-col cols="4" class="pa-0">
                            {{ itm.Elem }}
                        </v-col>
                        <v-col cols="4" class="pa-0 pr-6" align="right">
                            {{ itm.Min }}
                        </v-col>
                        <v-col cols="4" class="pa-0 pr-6" align="right">
                            {{ itm.Max }}
                        </v-col>
                    </v-row>
                </v-container>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: "MaterialDetails",

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.chemical-composition-header {
  font-weight: 600;
}

.chemical-composition {
  font-size: 0.8em;
  line-height: map-get($theme, line-height-card-text-caption);
}

.v-list .v-list-item--active {
  border-left: 3px solid map-get($theme, secondary-color);
}
</style>
