<template>
  <div>
    <v-card outlined>
      <v-form ref="selectForm">
        <v-card-text class="pa-0">
          <v-container fluid class="pa-0 px-5">
            <v-row>
              <v-overlay :absolute="true" :value="materialOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>

              <v-col cols="12" sm="3" class="px-0 pb-2">
                <v-card flat>
                  <v-card-title>
                    <span class="card-text-caption"
                      >{{ $t("sawCalc.inputMaterial.countryStandard") }} ({{
                        countryStandardsFiltered.length
                      }})</span
                    >
                  </v-card-title>
                  <v-list dense class="pt-0">
                    <v-list-item-group
                      v-model="countryStandardIndex"
                      color="primary"
                      class="pr-2"
                    >
                      <v-list-item
                        v-for="(item, i) in countryStandardsFiltered"
                        :key="i"
                        @click="countryStandardChanged(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.value"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4" class="px-0 pb-2">
                <v-card flat>
                  <v-card-title>
                    <span class="card-text-caption"
                      >{{ $t("sawCalc.inputMaterial.category") }} ({{
                        unsCategoriesFiltered.length
                      }})</span
                    >
                  </v-card-title>
                  <v-list dense class="pt-0">
                    <v-list-item-group
                      v-model="unsCategoryIndex"
                      color="primary"
                      class="pr-2"
                    >
                      <v-list-item
                        v-for="(item, i) in unsCategoriesFiltered"
                        :key="i"
                        @click="unsCategoryChanged(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.value"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" sm="5" class="px-0 pb-2 pr-2">
                <v-card flat>
                  <v-card-title>
                    <span class="card-text-caption"
                      >{{ $t("sawCalc.inputMaterial.material") }} ({{
                        materialsFiltered.length
                      }})</span
                    >
                  </v-card-title>
                  <v-list dense class="pt-0">
                    <v-list-item-group
                      v-model="materialIndex"
                      color="primary"
                      class="pr-2"
                    >
                      <v-list-item
                        v-for="(item, i) in materialsFiltered"
                        :key="i"
                        @click="materialChanged(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.description"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>

              <MaterialSelectDetails
                :item="material"
                :scales="scales"
                :key="materialSelectorDetailsKey"
              />

              <v-col
                cols="12"
                :sm="colsCount"
                class="px-1 pa-0"
                align="right"
                :class="{ 'pt-0 pb-3': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMaterial.clearButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!material.materialID"
                  @click="resetSelectForm()"
                ></v-btn>
              </v-col>
              <v-col
                v-if="user"
                cols="12"
                :sm="colsCount"
                class="px-1 pa-0"
                align="right"
                :class="{ 'pt-0 pb-3': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMaterial.addButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="addUserMaterialDisabled"
                  @click="addUserMaterial()"
                ></v-btn>
              </v-col>
              <v-col
                cols="12"
                :sm="colsCount"
                class="px-1 pa-0"
                align="right"
                :class="{ 'pt-0 pb-3': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMaterial.setMaterial')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!material.materialID"
                  @click="setMaterial(material)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import MaterialSelectDetails from "@/components/public/SawCalc/Selectors/Material/MaterialSelectDetails";

import { mapGetters } from "vuex";

export default {
  name: "MaterialSelect",

  components: {
    MaterialSelectDetails,
  },

  props: {
    materialStandards: {
      type: Array,
      required: true,
      default: () => [],
    },
    unsCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    subCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectMaterials: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      materialOverlay: false,
      countryStandard: null,
      unsCategory: null,
      countryStandardIndex: null,
      unsCategoryIndex: null,
      materialIndex: null,
    };
  },

  computed: {
    ...mapGetters(["material", "scales", "materialSelectorDetailsKey", "user", "resetInputs"]),
    countryStandardsFiltered() {
      let ids = this.selectMaterials.map((o) => o.materialStandardID);
      ids = [...new Set(ids)];

      const filtered = this.materialStandards.filter((item) =>
        ids.includes(item.id)
      );

      return filtered;
    },
    unsCategoriesFiltered() {
      if (!this.countryStandard) {
        return [];
      }

      const selectMaterials = this.selectMaterials.filter(
        (item) => item.materialStandardID == this.countryStandard.id
      );

      let ids = selectMaterials.map((o) => o.unsCategoryID);
      ids = [...new Set(ids)];

      const filtered = this.unsCategories.filter((item) =>
        ids.includes(item.id)
      );

      return filtered;
    },
    materialsFiltered() {
      if (!this.countryStandard || !this.unsCategory) {
        return [];
      }

      const filtered = this.selectMaterials.filter(
        (item) =>
          item.materialStandardID == this.countryStandard.id &&
          item.unsCategoryID == this.unsCategory.id
      );

      return filtered;
    },
    colsCount() {
      if (this.user) {
        return 4;
      } else {
        return 6;
      }
    },
    addUserMaterialDisabled() {
      if (
        this.material.materialID &&
        this.material.hardness &&
        this.material.hardness > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    setDefaults() {
      this.countryStandard = null;
      this.unsCategory = null;

      this.countryStandardIndex = null;
      this.unsCategoryIndex = null;
      this.materialIndex = null;
    },
    countryStandardChanged(item) {
      this.countryStandard = this.materialStandards.find(
        (itm) => itm.id == item.id
      );

      this.unsCategoryIndex = null;
      this.materialIndex = null;

      this.unsCategory = null;

      this.$store.dispatch("setMaterial", null);
    },
    unsCategoryChanged(item) {
      this.unsCategory = this.subCategories.find((itm) => itm.id == item.id);

      this.materialIndex = null;

      this.$store.dispatch("setMaterial", null);
    },
    materialChanged(item) {
      this.materialOverlay = true;

      this.$store.dispatch("getMaterialDetails", item).then(
        (response) => {
          this.materialOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.materialOverlay = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    setMaterial(material) {
      if (!material) {
        return;
      }

      this.$store.dispatch("setInputMaterial", material);

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputMaterial.material")}: ${
          material.designation
        } ${this.$t("sawCalc.inputMaterial.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.$store.dispatch("reloadMaterialSelectorDetailsComponent");
      this.close();
    },
    resetSelectForm() {
      this.$store.dispatch("setMaterial", null);
      this.$store.dispatch("setInputMaterial", null);

      this.$refs.selectForm.reset();
      this.setDefaults();
    },
    addUserMaterial() {
      this.$store.dispatch("addUserMaterial", this.material).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    close() {
      this.$store.dispatch("showMaterialSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      this.setDefaults()
    }
  },

  mounted() {
    this.setDefaults();
  },
};
</script>

<style lang="scss" scoped>
.v-item-group {
  border-top: 1px solid map-get($theme, primary-color);
}

.v-list {
  max-height: 190px;
  overflow-y: auto;
}

.v-list .v-list-item--active {
  border-left: 3px solid map-get($theme, secondary-color);
}
</style>
