<template>
  <div>
    <v-dialog
      v-model="conditionsSelectorDialog.show"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="520px"
    >
      <v-card>
        <v-card-title class="card-title">
          <h3>
            {{ $t("sawCalc.inputConditions.title") }}
          </h3>
          <v-spacer />
          <v-btn text icon color="primary" x-large @click="close">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-2">
          <v-card outlined>
            <v-container class="px-2 py-0">
              <v-row>
                <v-col class="pb-0">
                  <Conditions
                    :cantedHead="cantedHead"
                    :cantingDegrees="cantingDegrees"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Conditions from "@/components/public/SawCalc/Selectors/Conditions/Conditions";

import { mapGetters } from "vuex";

export default {
  name: "ConditionsSelector",

  components: {
    Conditions,
  },

  data() {
    return {
      cantedHead: false,
      cantingDegrees: 0,
    };
  },

  computed: {
    ...mapGetters([
      "conditionsSelectorDialog",
      "inputUOM",
      "axiosSource",
      "user",
      "inputMachine",
      "defaultCantingDegrees"
    ]),
    conditionsSelectorDialogShow() {
      return this.conditionsSelectorDialog.show;
    },
  },

  methods: {
    close() {
      this.$store.dispatch("showConditionsSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    conditionsSelectorDialogShow(val) {
      if (val) {
        this.cantedHead = this.inputMachine.cantedHead
          ? this.inputMachine.cantedHead
          : false;
        this.cantingDegrees = this.inputMachine.cantedHead ? this.defaultCantingDegrees : 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
