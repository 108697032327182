<template>
  <v-form ref="form">
    <v-container class="px-4">
      <v-row>
        <v-col cols="12" sm="3" class="px-2 pt-0">
          <span class="card-text-caption">
            {{ $t("sawCalc.inputConditions.lubrication") }}
          </span>
        </v-col>
        <v-col cols="12" sm="9" class="px-2 pt-0">
          <v-btn-toggle
            depressed
            tile
            dark
            v-model="conditions.lubrication"
            mandatory
            dense
          >
            <v-btn color="primary">
              <span>
                {{ $t("sawCalc.inputConditions.lubricationFloodLabel") }}
              </span>
            </v-btn>
            <v-btn color="primary">
              <span>
                {{ $t("sawCalc.inputConditions.lubricationMistLabel") }}
              </span>
            </v-btn>
            <v-btn color="primary">
              <span>
                {{ $t("sawCalc.inputConditions.lubricationDryLabel") }}
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="px-2 pt-0">
          <span class="card-text-caption">
            {{ $t("sawCalc.inputConditions.pinching") }}
          </span>
        </v-col>
        <v-col cols="12" sm="9" class="px-2 pt-0">
          <v-btn-toggle
            depressed
            tile
            dark
            v-model="conditions.pinching"
            mandatory
            dense
          >
            <v-btn color="primary">
              <span>
                {{ $t("sawCalc.inputConditions.noLabel") }}
              </span>
            </v-btn>
            <v-btn color="primary">
              <span>
                {{ $t("sawCalc.inputConditions.yesLabel") }}
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="px-2 py-0">
          <v-text-field
            :label="$t('sawCalc.inputConditions.cantingLabel')"
            v-model="canting"
            :rules="inputRules"
            :disabled="!cantedHead"
            type="number"
            @keydown.enter.prevent=""
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
          <v-btn
            depressed
            tile
            v-html="$t('sawCalc.inputConditions.clearButton')"
            class="primary white--text full-width"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="!dirty"
            @click="resetForm()"
          ></v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
          <v-btn
            depressed
            tile
            v-html="$t('sawCalc.inputConditions.setConditions')"
            class="primary white--text full-width"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="!valid"
            @click="setConditions(conditions)"
          ></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Conditions",

  props: {
    cantedHead: {
      type: Boolean,
      required: true,
    },
    cantingDegrees: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      canting: 0,
      conditions: {
        lubrication: 0,
        pinching: 0,
        canting: 0,
      },
      conditionsDefault: {
        lubrication: 0,
        pinching: 0,
        canting: 0,
      },
      inputRules: [
        (v) =>
          (!!v && v.length > 0) ||
          this.$t("sawCalc.inputConditions.inputRules"),
        (v) =>
          (!!v && this.validCanting) ||
          this.$t("sawCalc.inputConditions.inputRulesNumber"),
      ],
    };
  },

  computed: {
    ...mapGetters(["inputUOM", "inputConditions", "resetInputs"]),
    valid() {
      return (
        this.conditions != null &&
        this.conditions.lubrication >= 0 &&
        this.conditions.pinching >= 0 &&
        this.validCanting
      );
    },
    validCanting() {
      return this.conditions && parseFloat(this.conditions.canting) >= 0;
    },
    dirty() {
      return (
        JSON.stringify(this.conditions) !==
        JSON.stringify(this.conditionsDefault)
      );
    },
  },

  methods: {
    setFormDefaults() {
      this.conditions = JSON.parse(JSON.stringify(this.conditionsDefault));
      this.canting = this.cantingDegrees;
    },
    setConditions(conditions) {
      if (!conditions) {
        return;
      }

      this.conditions.canting = this.canting;

      this.$store.dispatch("setInputConditions", {
        conditions: conditions,
        uom: this.inputUOM,
      });

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputConditions.conditions")} ${this.$t(
          "sawCalc.inputConditions.set"
        )}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    resetForm() {
      this.setFormDefaults();
      this.$store.dispatch("setInputConditions", null);
    },
    close() {
      this.$store.dispatch("showConditionsSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    cantedHead(val) {
      if (val) {
        this.canting = this.cantingDegrees;
      } else {
        this.canting = 0;
      }
    },
    resetInputs() {
      this.setFormDefaults();
    },
  },

  mounted() {
    this.setFormDefaults();
  },
};
</script>
