<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12" sm="6" class="py-0">
        <v-container class="pa-0 px-3">
          <v-row align="center" justify="center">
            <v-col cols="12">
              {{ $t("sawCalc.inputDimensions.selectOrSpecifyDimensions") }}
            </v-col>
            <v-col class="pa-0">
              <v-tabs
                v-model="selectedTab"
                fixed-tabs
                center-active
                show-arrows
                background-color="transparent"
                slider-color="primary"
                slider-size="2"
              >
                <v-tab
                  v-for="(item, i) in tabs"
                  :key="`${i}-tab`"
                  class="selector-tab"
                  :class="{
                    'primary--text': !tabActive(item.id),
                    'secondary--text': tabActive(item.id),
                  }"
                  >{{ item.title }}</v-tab
                >
                <v-tab-item>
                  <v-form ref="formStandard">
                    <v-card flat>
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col class="pr-6 pb-0">
                              <v-autocomplete
                                :label="
                                  $t('sawCalc.inputDimensions.iBeamGroupLabel')
                                "
                                :items="iBeamGroups"
                                item-text="iBeamGroup"
                                item-value="iBeamGroupID"
                                @change="iBeamGroupChanged"
                                autofocus
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="pr-6 py-0">
                              <v-autocomplete
                                :label="
                                  $t(
                                    'sawCalc.inputDimensions.configurationLabel'
                                  )
                                "
                                :items="iBeamStandardsFiltered"
                                item-text="description"
                                item-value="iBeamStandardID"
                                @change="iBeamStandardChanged"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row v-if="valid" class="px-3">
                            <v-col cols="3" class="pa-0">
                              <span>A:</span>
                              <span>{{ getDimension("A") }}</span>
                            </v-col>
                            <v-col cols="3" class="pa-0">
                              <span>B:</span>
                              <span>{{ getDimension("B") }}</span>
                            </v-col>
                            <v-col cols="3" class="pa-0">
                              <span>C:</span>
                              <span>{{ getDimension("C") }}</span>
                            </v-col>
                            <v-col cols="3" class="pa-0">
                              <span>D:</span>
                              <span>{{ getDimension("D") }}</span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-tab-item>

                <v-tab-item>
                  <v-form ref="formCustom">
                    <v-card flat="">
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                              <v-text-field
                                v-if="inputUOM == 0"
                                label="A, in"
                                v-model="custom.aImperial"
                                :rules="inputRules"
                                type="number"
                                @change="aImperialChanged"
                                autofocus
                              >
                              </v-text-field>
                              <v-text-field
                                v-else
                                label="A, mm"
                                v-model="custom.aMetric"
                                :rules="inputRules"
                                type="number"
                                @change="aMetricChanged"
                                autofocus
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                              <v-text-field
                                v-if="inputUOM == 0"
                                label="B, in"
                                v-model="custom.bImperial"
                                :rules="inputRulesSideToSide"
                                type="number"
                                @change="bImperialChanged"
                              >
                              </v-text-field>
                              <v-text-field
                                v-else
                                label="B, mm"
                                v-model="custom.bMetric"
                                :rules="inputRulesSideToSide"
                                type="number"
                                @change="bMetricChanged"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                              <v-text-field
                                v-if="inputUOM == 0"
                                label="C, in"
                                v-model="custom.cImperial"
                                :rules="inputRulesC"
                                type="number"
                                @change="cImperialChanged"
                              >
                              </v-text-field>
                              <v-text-field
                                v-else
                                label="C, mm"
                                v-model="custom.cMetric"
                                :rules="inputRulesC"
                                type="number"
                                @change="cMetricChanged"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                              <v-text-field
                                v-if="inputUOM == 0"
                                label="D, in"
                                v-model="custom.dImperial"
                                :rules="inputRulesD"
                                type="number"
                                @change="dImperialChanged"
                              >
                              </v-text-field>
                              <v-text-field
                                v-else
                                label="D, mm"
                                v-model="custom.dMetric"
                                :rules="inputRulesD"
                                type="number"
                                @change="dMetricChanged"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" sm="6" align="center" justify="center" class="py-0">
        <v-container class="pa-0">
          <v-row align="center" justify="center">
            <v-col>
              <img
                src="/images/Dimensions/ibeam-dimensions.png"
                width="280px"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="pa-0 px-4">
      <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
        <v-btn
          depressed
          tile
          v-html="$t('sawCalc.inputDimensions.clearButton')"
          class="primary white--text full-width"
          :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
          :disabled="!dirty"
          @click="resetForm()"
        ></v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
        <v-btn
          depressed
          tile
          v-html="$t('sawCalc.inputDimensions.setDimensions')"
          class="primary white--text full-width"
          :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
          :disabled="!valid"
          @click="setDimensions()"
        ></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { calculations } from "@/helpers/Calculations";

export default {
  name: "IBeam",

  props: {
    shape: {
      type: Object,
      required: true,
    },
    iBeamGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    iBeamStandards: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      selectedTab: 0,
      custom: {
        aImperial: null,
        aMetric: null,
        bImperial: null,
        bMetric: null,
        cImperial: null,
        cMetric: null,
        dImperial: null,
        dMetric: null,
        descriptionImperial: null,
        descriptionMetric: null,
      },
      customDefault: {
        aImperial: null,
        aMetric: null,
        bImperial: null,
        bMetric: null,
        cImperial: null,
        cMetric: null,
        dImperial: null,
        dMetric: null,
        descriptionImperial: null,
        descriptionMetric: null,
      },
      iBeamGroup: null,
      iBeamStandard: null,
      inputRules: [
        (v) =>
          (!!v && v.length > 0) ||
          this.$t("sawCalc.inputDimensions.inputRules"),
        (v) =>
          (!!v && this.validA) ||
          this.$t("sawCalc.inputDimensions.inputRulesNumber"),
      ],
      inputRulesSideToSide: [
        (v) =>
          (!!v && this.validB) ||
          this.$t("sawCalc.inputDimensions.inputRulesSideToSide"),
      ],
      inputRulesC: [
        (v) =>
          (!!v && this.validC) ||
          this.$t("sawCalc.inputDimensions.inputRulesThickness"),
      ],
      inputRulesD: [
        (v) =>
          (!!v && this.validD) ||
          this.$t("sawCalc.inputDimensions.inputRulesThickness"),
      ],
    };
  },

  computed: {
    ...mapGetters(["inputUOM", "resetInputs"]),
    standard() {
      return this.selectedTab == 0;
    },
    tabs() {
      return [
        {
          id: 0,
          title: this.$t("sawCalc.inputDimensions.standard"),
        },
        {
          id: 1,
          title: this.$t("sawCalc.inputDimensions.custom"),
        },
      ];
    },
    iBeamStandardsFiltered() {
      if (this.iBeamGroup) {
        const list = this.iBeamStandards.filter(
          (item) => item.iBeamGroupID == this.iBeamGroup.iBeamGroupID
        );

        return list;
      }
      return [];
    },
    metric() {
      return this.inputUOM > 0;
    },
    valid() {
      if (this.standard) {
        return this.iBeamStandard != null;
      } else {
        if (!this.custom) {
          return false;
        }

        return this.validA && this.validB && this.validC && this.validD;
      }
    },
    validA() {
      if (!this.custom) {
        return false;
      }

      let a = this.metric ? this.custom.aMetric : this.custom.aImperial;

      if (a != null) {
        a = parseFloat(a);
      }

      return a != null && a > 0;
    },
    validB() {
      if (!this.custom) {
        return false;
      }

      let a = this.metric ? this.custom.aMetric : this.custom.aImperial;

      if (a != null) {
        a = parseFloat(a);
      }

      let b = this.metric ? this.custom.bMetric : this.custom.bImperial;

      if (b != null) {
        b = parseFloat(b);
      }

      return a != null && b != null && b > 0 && a >= b;
    },
    validC() {
      if (!this.custom) {
        return false;
      }

      let a = this.metric ? this.custom.aMetric : this.custom.aImperial;

      if (a != null) {
        a = parseFloat(a);
      }

      let c = this.metric ? this.custom.cMetric : this.custom.cImperial;

      if (c != null) {
        c = parseFloat(c);
      }

      return a != null && c != null && c > 0 && c <= a * 0.2;
    },
    validD() {
      if (!this.custom) {
        return false;
      }

      let a = this.metric ? this.custom.aMetric : this.custom.aImperial;

      if (a != null) {
        a = parseFloat(a);
      }

      let d = this.metric ? this.custom.dMetric : this.custom.dImperial;

      if (d != null) {
        d = parseFloat(d);
      }

      return a != null && d != null && d > 0 && d <= a * 0.2;
    },
    dirty() {
      if (this.standard) {
        return this.iBeamStandard != null;
      } else {
        return (
          JSON.stringify(this.custom) !== JSON.stringify(this.customDefault)
        );
      }
    },
    uom() {
      if (this.metric) {
        return "mm";
      } else {
        return "in";
      }
    },
  },

  methods: {
    setFormDefaults() {
      this.setStandardDefaults();
      this.setCustomDefaults();
    },
    setStandardDefaults() {
      this.iBeamStandard = null;
    },
    setCustomDefaults() {
      this.custom = JSON.parse(JSON.stringify(this.customDefault));
    },
    resetForm() {
      if (this.standard) {
        this.$refs.formStandard.reset();
        this.setStandardDefaults();
      } else {
        this.$refs.formCustom.reset();
        this.setCustomDefaults();
      }

      this.$store.dispatch("setInputDimensions", null);
    },
    getDimension(dim) {
      if (!this.iBeamStandard) {
        return "";
      }

      if (dim == "A") {
        return this.metric
          ? this.iBeamStandard.aMetric
          : this.iBeamStandard.aImperial + this.uom;
      } else if (dim == "B") {
        return this.metric
          ? this.iBeamStandard.bMetric
          : this.iBeamStandard.bImperial + this.uom;
      } else if (dim == "C") {
        return this.metric
          ? this.iBeamStandard.cMetric
          : this.iBeamStandard.cImperial + this.uom;
      } else if (dim == "D") {
        return this.metric
          ? this.iBeamStandard.dMetric
          : this.iBeamStandard.dImperial + this.uom;
      }
    },
    setDimensions() {
      if (this.standard) {
        this.setStandard();
      } else {
        this.setCustom();
      }
    },
    setStandard() {
      if (!this.iBeamStandard) {
        return;
      }

      const measurementMetric =
        ((this.iBeamStandard.aMetric - 2 * this.iBeamStandard.dMetric) *
          this.iBeamStandard.cMetric +
          2 * this.iBeamStandard.bMetric * this.iBeamStandard.dMetric) /
        100;
      const measurementImperial =
        (this.iBeamStandard.aImperial - 2 * this.iBeamStandard.dImperial) *
          this.iBeamStandard.cImperial +
        2 * this.iBeamStandard.bImperial * this.iBeamStandard.dImperial;

      this.iBeamStandard.descriptionImperial = this.iBeamStandard.description;
      this.iBeamStandard.descriptionMetric = this.iBeamStandard.description;

      this.$store.dispatch("setInputDimensions", {
        dimensions: {
          shape: this.shape,
          dimensionDetails: this.iBeamStandard,
          measurement: {
            measurementImperial: measurementImperial,
            measurementMetric: measurementMetric,
          },
        },
        uom: this.inputUOM,
      });

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputDimensions.dimensions")} ${this.$t(
          "sawCalc.inputDimensions.for"
        )}: ${this.shape.title} ${this.$t("sawCalc.inputDimensions.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    setCustom() {
      if (!this.custom) {
        return;
      }

      const custom = JSON.parse(JSON.stringify(this.custom));

      custom.aImperial = parseFloat(this.custom.aImperial);
      custom.aMetric = parseFloat(this.custom.aMetric);
      custom.bImperial = parseFloat(this.custom.bImperial);
      custom.bMetric = parseFloat(this.custom.bMetric);
      custom.cImperial = parseFloat(this.custom.cImperial);
      custom.cMetric = parseFloat(this.custom.cMetric);
      custom.dImperial = parseFloat(this.custom.dImperial);
      custom.dMetric = parseFloat(this.custom.dMetric);

      const measurementMetric =
        ((custom.aMetric - 2 * custom.dMetric) * custom.cMetric +
          2 * custom.bMetric * custom.dMetric) /
        100;
      const measurementImperial =
        (custom.aImperial - 2 * custom.dImperial) * custom.cImperial +
        2 * custom.bImperial * custom.dImperial;

      custom.descriptionImperial =
        calculations.inValue(custom.aImperial, 2, true) +
        " x " +
        calculations.inValue(custom.bImperial, 2, true);
      custom.descriptionMetric =
        calculations.mmValue(custom.aMetric, 1, true) +
        " x " +
        calculations.mmValue(custom.bMetric, 1, true);

      this.$store.dispatch("setInputDimensions", {
        dimensions: {
          shape: this.shape,
          dimensionDetails: custom,
          measurement: {
            measurementImperial: measurementImperial,
            measurementMetric: measurementMetric,
          },
        },
        uom: this.inputUOM,
      });

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputDimensions.dimensions")} ${this.$t(
          "sawCalc.inputDimensions.for"
        )}: ${this.shape.title} ${this.$t("sawCalc.inputDimensions.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    tabActive(id) {
      return id == this.selectedTab;
    },
    iBeamGroupChanged(id) {
      this.iBeamGroup = this.iBeamGroups.find(
        ({ iBeamGroupID }) => iBeamGroupID === id
      );
    },
    iBeamStandardChanged(id) {
      const iBeamStandard = this.iBeamStandards.find(
        ({ iBeamStandardID }) => iBeamStandardID === id
      );
      iBeamStandard.iBeamGroup = this.iBeamGroup.iBeamGroup;

      iBeamStandard.aImperial = iBeamStandard.depth;
      iBeamStandard.bImperial = iBeamStandard.flangeWidth;
      iBeamStandard.cImperial = iBeamStandard.webThickness;
      iBeamStandard.dImperial = iBeamStandard.flangeThickness;

      iBeamStandard.aMetric = calculations.inToMM(iBeamStandard.depth);
      iBeamStandard.bMetric = calculations.inToMM(iBeamStandard.flangeWidth);
      iBeamStandard.cMetric = calculations.inToMM(iBeamStandard.webThickness);
      iBeamStandard.dMetric = calculations.inToMM(
        iBeamStandard.flangeThickness
      );

      this.iBeamStandard = iBeamStandard;
    },
    aImperialChanged() {
      this.custom.aMetric = calculations.inToMM(this.custom.aImperial);
    },
    aMetricChanged() {
      this.custom.aImperial = calculations.mmToIn(this.custom.aMetric);
    },
    bImperialChanged() {
      this.custom.bMetric = calculations.inToMM(this.custom.bImperial);
    },
    bMetricChanged() {
      this.custom.bImperial = calculations.mmToIn(this.custom.bMetric);
    },
    cImperialChanged() {
      this.custom.cMetric = calculations.inToMM(this.custom.cImperial);
    },
    cMetricChanged() {
      this.custom.cImperial = calculations.mmToIn(this.custom.cMetric);
    },
    dImperialChanged() {
      this.custom.dMetric = calculations.inToMM(this.custom.dImperial);
    },
    dMetricChanged() {
      this.custom.dImperial = calculations.mmToIn(this.custom.dMetric);
    },
    close() {
      this.$store.dispatch("showDimensionsSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      if (this.$refs.formStandard) {
        this.$refs.formStandard.reset();
      }

      if (this.$refs.formCustom) {
        this.$refs.formCustom.reset();
      }

      this.setFormDefaults();
    },
  },

  mounted() {
    this.setFormDefaults();
  },
};
</script>
