<template>
  <v-container fluid class="py-0">
    <v-row justify="center" @click="showSelector">
      <v-col class="pa-0">
        <v-card flat 
          :class="{
            'card-input': selectedRecommendationForCustom == null,
            'card-disabled': selectedRecommendationForCustom != null,
          }">
          <v-card-text class="pa-2">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col
                  class="px-2 py-0"
                  :class="{
                    'title-ready': inputDimensions.shape != null,
                    'title-not-ready': inputDimensions.shape == null,
                    'card-disabled': selectedRecommendationForCustom != null,
                  }"
                  ><h4>{{ $t("sawCalc.inputDimensions.title") }}</h4></v-col
                >
              </v-row>
              <v-row v-if="dimensionsReady">
                <v-col class="px-2 pt-1 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.inputDimensions.shape')"
                /></v-col>
              </v-row>
              <v-row v-if="dimensionsReady">
                <v-col 
                  class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="getShapeTitle"
                /></v-col>
              </v-row>
              <v-row v-if="dimensionsReady">
                <v-col class="px-2 pt-1 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.inputDimensions.dimensions')"
                /></v-col>
              </v-row>
              <v-row v-if="dimensionsReady">
                <v-col 
                  class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="getDescription" />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="px-2 pt-1 pb-0 card-text-error"
                  ><span v-html="$t('sawCalc.inputDimensions.errorNotLoaded')"
                /></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InputDimensions",

  computed: {
    ...mapGetters([
      "inputDimensions",
      "inputUOM",
      "measurementUOM",
      "dimensionsReady",
      "selectedRecommendationForCustom",
    ]),
    metric() {
      return this.inputUOM > 0;
    },
    getShapeTitle() {
      if (!this.inputDimensions || !this.inputDimensions.shape) {
        return "";
      }
      
      return this.$t(`sawCalc.inputDimensions.${this.inputDimensions.shape.title}`)
    },
    getDescription() {
      if (!this.inputDimensions || !this.inputDimensions.dimensionDetails) {
        return "";
      }

      let description = "";

      if (this.metric) {
        description = this.inputDimensions.dimensionDetails.descriptionMetric;
      } else {
        description = this.inputDimensions.dimensionDetails.descriptionImperial;
      }

      return description;
    },
  },

  methods: {
    showSelector() {
      if (
        this.dimensionsReady &&
        this.selectedRecommendationForCustom == null
      ) {
        this.$store.dispatch("showDimensionsSelectorDialog", true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-input {
  cursor: pointer;
}
.card-disabled {
  color: map-get($theme, footer-link-color);
  cursor: not-allowed;
}
</style>