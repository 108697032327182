<template>
  <v-container class="px-3 py-0">
    <v-row>
      <v-col
        cols="6"
        class="pa-0 black--text"
        :class="{ 'pt-2': $vuetify.breakpoint.smAndDown }"
      >
        <h4 :class="{ disabled: !enabled }">
          {{ $t("sawCalc.costCalculations.title") }}
        </h4>
      </v-col>
      <v-col
        cols="6"
        class="pa-0"
        :class="{ 'pt-2': $vuetify.breakpoint.smAndDown }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="!enabled"
                @click="refresh()">
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('sawCalc.costCalculations.refresh') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="!enabled"
                @click="clear()">
                <v-icon>mdi-restart-alert</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('sawCalc.costCalculations.clear') }}</span>
          </v-tooltip>
        </v-col
      >
    </v-row>
    <v-row>
      <v-col class="pa-0 pt-3">
        <div style="max-width: 100px;">
          <v-text-field
            v-model="costCalculation.pricePerBlade"
            hide-details
            type="number"
            :prefix="currencySymbol"
            :label="$t('sawCalc.costCalculations.pricePerBlade')"
            class="pa-0 ma-0"
            :disabled="!enabled"
            @input="pricePerBladeChanged"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pa-0 pt-3 pb-1 card-text-caption"
        :class="{ 'pt-5': $vuetify.breakpoint.smAndDown }"
      >
        <span v-html="$t('sawCalc.costCalculations.bladeLife')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-btn-toggle
          depressed
          tile
          :dark="enabled"
          mandatory
          :value="costCalculation.bladeLife"
          @change="bladeLifeChanged"
        >
          <v-btn
            depressed
            tile
            color="primary"
            v-html="$t('sawCalc.costCalculations.cutsPerBlade')"
            :x-small="$vuetify.breakpoint.mdAndDown"
            :disabled="!enabled"
          ></v-btn>
          <v-btn
            depressed
            tile
            color="primary"
            v-html="$t('sawCalc.costCalculations.measurement')"
            :x-small="$vuetify.breakpoint.mdAndDown"
            :disabled="!enabled"
          ></v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="bladeLife == 0"
        class="pa-0 pt-3 pr-3"
        style="max-width: 100px;"
      >
        <div style="max-width: 90px;">
          <v-text-field
            ref="refCutsPerBlade"
            v-model="costCalculation.cutsPerBlade"
            hide-details
            type="number"
            :label="$t('sawCalc.costCalculations.cutsPerBlade')"
            class="pa-0 ma-0"
            :disabled="!enabled"
            @input="cutsPerBladeChanged"
          ></v-text-field>
        </div>
      </v-col>
      <v-col v-else class="pa-0 pt-3">
        <div style="max-width: 120px;">
          <v-text-field
            ref="refMeasurement"
            v-model="measurement"
            hide-details
            type="number"
            :suffix="measurementUOM"
            :label="$t('sawCalc.costCalculations.measurement')"
            class="pa-0 ma-0"
            :disabled="!enabled"
            @input="measurementChanged"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 pt-5">
        <div style="max-width: 150px;">
          <v-text-field
            v-model="costCalculation.laborBurdenRate"
            hide-details
            type="number"
            :prefix="currencySymbol"
            :label="$t('sawCalc.costCalculations.laborBurdenRate')"
            class="pa-0 ma-0"
            :disabled="!enabled"
            @input="laborBurdenRateChanged"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pa-0 pt-4 card-text-caption"
        :class="{ 'pt-5': $vuetify.breakpoint.smAndDown }"
      >
        <span v-html="$t('sawCalc.costCalculations.totalCosts')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="px-4 py-1 card-text-description"
        :class="{ disabled: !enabled }"
        style="max-width: 150px"
        >{{ currencySymbol }}<span>{{ costCalculation.costsPerCut }}</span> /
        <span v-html="$t('sawCalc.costCalculations.cut')" />
      </v-col>
      <v-col
        class="px-4 py-1 card-text-description"
        :class="{ disabled: !enabled }"
        style="max-width: 150px"
        >{{ currencySymbol
        }}<span>{{ costCalculation.costsPerMeasurement }}</span> /
        {{ measurementUOM }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { calculations } from "@/helpers/Calculations";

export default {
  name: "CostCalculation",

  props: {
    recommendation: {
      type: Object,
      required: true,
      default: () => {},
    },
    recommendationIndex: {
      type: Number,
      required: true,
    },
    costCalculationOriginal: {
      type: Object,
      required: true,
      default: () => {},
    },
    reset: {
      type: Number,
      required: true,
    },
    update: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      costCalculation: {},
      bladeLife: 0,
      measurement: null,
    };
  },

  computed: {
    ...mapGetters(["currencySymbol", "inputUOM", "measurementUOM"]),
    metric() {
      return this.inputUOM > 0;
    },
    enabled() {
      return (
        this.recommendation != undefined &&
        this.recommendation.cutTime != undefined &&
        this.recommendation.cutTime != null &&
        this.recommendation.cutTime > 0
      );
    },
  },

  methods: {
    pricePerBladeChanged() {
      this.updateCostCalculation();
    },
    bladeLifeChanged(val) {
      this.costCalculation.bladeLife = val;
      this.bladeLife = val;

      this.updateCostCalculation();

      setTimeout(() => {
        if (this.$refs.refCutsPerBlade != undefined) {
          this.$refs.refCutsPerBlade.focus();

          let cutsPerBladeEl = this.$refs.refCutsPerBlade.$el.querySelector(
            "input"
          );

          cutsPerBladeEl.select();
        }
      }, 100);

      setTimeout(() => {
        if (this.$refs.refMeasurement != undefined) {
          this.$refs.refMeasurement.focus();

          let measurementEl = this.$refs.refMeasurement.$el.querySelector(
            "input"
          );
          measurementEl.select();
        }
      }, 100);
    },
    cutsPerBladeChanged() {
      this.updateCostCalculation();
    },
    measurementChanged(val) {
      if (
        this.costCalculation.measurement == undefined ||
        this.costCalculation.measurement == null
      ) {
        this.costCalculation.measurement = {};
      }

      if (this.metric) {
        this.costCalculation.measurement.measurementMetric = parseInt(val);
        this.costCalculation.measurement.measurementImperial = calculations.sqCMToSqIn(
          val
        );
      } else {
        this.costCalculation.measurement.measurementImperial = parseInt(val);
        this.costCalculation.measurement.measurementMetric = calculations.sqInToSqCM(
          val
        );
      }

      this.measurement = val ? val : "";

      this.updateCostCalculation();
    },
    laborBurdenRateChanged() {
      this.updateCostCalculation();
    },
    updateCostCalculation() {
      if (this.costCalculation.bladeLife == undefined) {
        this.costCalculation.bladeLife = 0;
      }

      const cuttingRate = this.inputUOM == 0 
        ? this.recommendation.cuttingRateImperial
        : this.recommendation.cuttingRateMetric;

      this.$store
        .dispatch("updateCostCalculation", {
          cutTime: this.recommendation.cutTime,
          cuttingRate: cuttingRate,
          costCalculation: this.costCalculation,
          recommendationIndex: this.recommendationIndex,
        })
        .then(() => {})
        .catch(() => {});
    },
    clear() {
      this.costCalculation.bladeLife = 0;
      this.costCalculation.pricePerBlade = null;
      this.costCalculation.cutsPerBlade = null;
      this.costCalculation.measurement = {};
      this.costCalculation.measurement.measurementImperial = null;
      this.costCalculation.measurement.measurementMetric = null;
      this.costCalculation.laborBurdenRate = null;
      this.costCalculation.costsPerCut = null;
      this.costCalculation.costsPerMeasurement = null;

      this.bladeLife = 0;
      this.measurement = null;
    },
    refresh() {
      const bladeLife = this.bladeLife
      const measurement = this.measurement
      
      const costCalculation = JSON.parse(JSON.stringify(this.costCalculation));
      this.clear();
      
      this.$nextTick(() => {
        this.costCalculation = costCalculation

        this.bladeLife = bladeLife
        this.measurement = measurement
      });
    }
  },

  watch: {
    inputUOM(val) {
      if (
        this.costCalculation == undefined ||
        this.costCalculation == null ||
        this.costCalculation.measurement == undefined ||
        this.costCalculation.measurement == null
      ) {
        this.measurement = null;
        return;
      }

      if (val == 0) {
        this.measurement = this.costCalculation.measurement.measurementImperial;
      } else {
        this.measurement = this.costCalculation.measurement.measurementMetric;
      }
    },
    reset() {
      this.clear();
    },
    update(){
      // console.log("watch update")
    }
  },

  created() {
    this.costCalculation = this.costCalculationOriginal;

    if (
      this.costCalculation.bladeLife == undefined ||
      this.costCalculation.bladeLife == null
    ) {
      this.costCalculation.bladeLife = 0;
      this.bladeLife = 0;
    } else {
      this.bladeLife = this.costCalculation.bladeLife;
    }

    if (
      this.costCalculation.measurement != undefined &&
      this.costCalculation.measurement != null
    ) {
      if (this.metric) {
        this.measurement = this.costCalculation.measurement.measurementMetric;
      } else {
        this.measurement = this.costCalculation.measurement.measurementImperial;
      }
    }
  },
};
</script>

<style lang="scss" scopped>
.v-text-field input {
  font-size: 0.9em;
}
.v-label {
  font-size: map-get($theme, line-height-card-text-caption);
}

.v-text-field__suffix {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-copy);
  font-size: map-get($theme, font-size-card-text-caption);
  line-height: map-get($theme, line-height-card-text-caption);
  color: map-get($theme, text-color);
  margin-right: 5px;
}

.v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  width: 0px;
}
.v-btn {
  max-height: 30px;
  min-height: 23px;
}
.disabled {
  color: map-get($theme, footer-link-color);
}
</style>
