<template>
  <div>
    <v-card outlined>
      <v-form ref="unknownForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-overlay :absolute="true" :value="machineOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>
              <v-col v-if="user" cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  autofocus
                  :label="$t('sawCalc.inputMachine.manufacturerLabel')"
                  :disabled="unknownOverlay"
                  v-model="unknown.manufacturerName"
                  :rules="unknownInputRules"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="user" cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.modelLabel')"
                  :disabled="unknownOverlay"
                  v-model="unknown.model"
                  :rules="unknownInputRules"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="px-2 pr-7 pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeWidthImperialLabel')"
                  :items="bladeWidthImperialList"
                  item-text="text"
                  item-value="value"
                  :disabled="unknownOverlay"
                  @change="bladeWidthImperialChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="5" class="px-2 pr-7  pt-0">
                <v-autocomplete
                  :label="
                    $t('sawCalc.inputMachine.bladeThicknessImperialLabel')
                  "
                  :items="bladeThicknessImperialList"
                  :disabled="unknownOverlay"
                  @change="bladeThicknessImperialChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" class="px-2 pr-7 pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeLengthFtLabel')"
                  :items="bladeLengthFtList"
                  :disabled="unknownOverlay"
                  @change="bladeLengthFtChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pr-7 pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeLengthInLabel')"
                  :items="bladeLengthInList"
                  :disabled="unknownOverlay"
                  @change="bladeLengthInChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" class="px-2 pr-7 pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeLengthFrLabel')"
                  :items="bladeLengthFrList"
                  item-text="text"
                  item-value="value"
                  :disabled="unknownOverlay"
                  @change="bladeLengthFrChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.machineOrientationLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="unknown.machineOrientation"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="unknownOverlay">
                    <span>
                      {{
                        $t(
                          "sawCalc.inputMachine.machineOrientationHorizontalLabel"
                        )
                      }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="unknownOverlay">
                    <span>
                      {{
                        $t(
                          "sawCalc.inputMachine.machineOrientationVerticalLabel"
                        )
                      }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.scissorPivotLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="unknown.scissorPivot"
                  mandatory
                  dense
                >
                  <v-btn
                    color="primary"
                    :disabled="!scissorPivotEnabled || unknownOverlay"
                  >
                    <span>
                      {{ $t("sawCalc.inputMachine.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!scissorPivotEnabled || unknownOverlay"
                  >
                    <span>
                      {{ $t("sawCalc.inputMachine.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.cantedHeadLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="unknown.cantedHead"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="unknownOverlay">
                    <span>
                      {{ $t("sawCalc.inputMachine.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="unknownOverlay">
                    <span>
                      {{ $t("sawCalc.inputMachine.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="px-2 pt-0">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.bandSpeedMinImperialLabel')"
                  :disabled="unknownOverlay"
                  v-model="unknown.bandSpeedMinImperial"
                  :rules="numberInputRules"
                  type="number"
                  @change="bandSpeedMinImperialChanged"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5" class="px-2 pt-0">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.bandSpeedMaxImperialLabel')"
                  :disabled="unknownOverlay"
                  v-model="unknown.bandSpeedMaxImperial"
                  :rules="numberInputRules"
                  type="number"
                  @change="bandSpeedMaxImperialChanged"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="colsCount" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.clearButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!unknownDirty || unknownOverlay"
                  @click="resetUnknownForm()"
                ></v-btn>
              </v-col>
              <v-col
                v-if="user"
                cols="12"
                :sm="colsCount"
                class="px-1 pt-0"
                align="right"
              >
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.addButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!unknownValid || unknownOverlay"
                  @click="addUnknownMachine()"
                ></v-btn>
              </v-col>
              <v-col cols="12" :sm="colsCount" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.setMachine')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!unknownValid || unknownOverlay"
                  @click="setUnknownMachine(unknown)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { calculations } from "@/helpers/Calculations";

export default {
  name: "UnknownImperial",

  components: {},

  props: {
    bladeWidthMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeWidthImperialList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeThicknessMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeThicknessImperialList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthFtList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthInList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthFrList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      machineOverlay: false,
      unknown: {
        manufacturerName: "",
        model: "",
        bladeWidthImperial: null,
        bladeThicknessImperial: null,
        bladeLengthFt: null,
        bladeLengthIn: null,
        bladeLengthFr: null,
        machineOrientation: 0,
        scissorPivot: null,
        cantedHead: 0,
        bandSpeedMinImperial: "",
        bandSpeedMaxImperial: "",
      },
      unknownDefault: {
        manufacturerName: "",
        model: "",
        bladeWidthImperial: null,
        bladeThicknessImperial: null,
        bladeLengthFt: null,
        bladeLengthIn: null,
        bladeLengthFr: null,
        machineOrientation: 0,
        scissorPivot: null,
        cantedHead: 0,
        bandSpeedMinImperial: "",
        bandSpeedMaxImperial: "",
      },
      unknownOverlay: false,
      unknownInputRules: [
        (v) =>
          (!!v && v.length > 0) || this.$t("sawCalc.inputMachine.inputRules"),
      ],
      numberInputRules: [
        (v) =>
          (!!v && v > 0) || this.$t("sawCalc.inputMachine.inputRules"),
      ],
    };
  },

  computed: {
    ...mapGetters(["inputUOM", "machine", "user", "resetInputs"]),
    unknownValid() {
      if (!this.unknown) {
        return false;
      }

      const bladeWidth = this.unknown.bladeWidthImperial;
      const bladeThickness = this.unknown.bladeThicknessImperial;
      const bladeLength = this.unknown.bladeLengthFt;
      const bandSpeedMin = this.unknown.bandSpeedMinImperial;
      const bandSpeedMax = this.unknown.bandSpeedMaxImperial;

      let valid =
        this.unknown &&
        bladeWidth &&
        bladeThickness &&
        bladeLength &&
        bandSpeedMin &&
        bandSpeedMax;

      if (this.user) {
        valid = this.unknown.manufacturerName && this.unknown.model && valid;
      }

      return valid;
    },
    unknownDirty() {
      return (
        JSON.stringify(this.unknown) !== JSON.stringify(this.unknownDefault)
      );
    },
    scissorPivotEnabled() {
      return this.unknown.machineOrientation == 0;
    },
    colsCount() {
      if (this.user) {
        return 4;
      } else {
        return 6;
      }
    },
  },

  methods: {
    bladeWidthImperialChanged(item) {
      if (item == undefined) {
        this.unknown.bladeWidthImperial = null;
        return;
      }

      const bladeWidthImperial = this.bladeWidthImperialList.find(
        ({ value }) => value === item
      );

      this.unknown.bladeWidthImperial = bladeWidthImperial;
      this.unknown.bladeWidthIn = parseInt(
        this.unknown.bladeWidthImperial.value
      );
      this.unknown.bladeWidthFr =
        this.unknown.bladeWidthImperial.value - this.unknown.bladeWidthIn;

      const machine = {
        bladeWidthImperial: this.unknown.bladeWidthImperial,
      }

      const bladeWidth = calculations.getBladeWidth(machine, false, this.bladeWidthImperialList, this.bladeWidthMetricList);
      this.unknown.bladeWidthMetric = bladeWidth.mm;
    },
    bladeThicknessImperialChanged(item) {
      if (item == undefined) {
        this.unknown.bladeThicknessImperial = null;
        return;
      }

      this.unknown.bladeThicknessImperial = item;
      
      const machine = {
        bladeThicknessImperial: item,
      }

      const bladeThickness = calculations.getBladeThickness(machine, false, this.bladeThicknessImperialList, this.bladeThicknessMetricList);
      this.unknown.bladeThicknessMetric= bladeThickness.mm;
    },
    bladeLengthFtChanged(item) {
      if (item == undefined) {
        this.unknown.bladeLengthFt = null;
        return;
      }

      this.unknown.bladeLengthFt = item;

      const machine = {
        bladeLengthFt: item,
        bladeLengthIn: this.unknown.bladeLengthIn,
        bladeLengthFr: this.unknown.bladeLengthFr
      }

      const bladeLength = calculations.getBladeLength(machine, false)
      this.unknown.bladeLengthMetric = bladeLength.mm;
    },
    bladeLengthInChanged(item) {
      if (item == undefined) {
        this.unknown.bladeLengthIn = null;
        return;
      }

      this.unknown.bladeLengthIn = item;

      const machine = {
        bladeLengthFt: this.unknown.bladeLengthFt,
        bladeLengthIn: item,
        bladeLengthFr: this.unknown.bladeLengthFr
      }

      const bladeLength = calculations.getBladeLength(machine, false)
      this.unknown.bladeLengthMetric = bladeLength.mm;
    },
    bladeLengthFrChanged(item) {
      if (item == undefined) {
        this.unknown.bladeLengthFr = null;
        return;
      }

      const bladeLengthFr = this.bladeLengthFrList.find(
        ({ value }) => value === item
      );

      this.unknown.bladeLengthFr = bladeLengthFr;

      const machine = {
        bladeLengthFt: this.unknown.bladeLengthFt,
        bladeLengthIn: this.unknown.bladeLengthIn,
        bladeLengthFr: item
      }

      const bladeLength = calculations.getBladeLength(machine, false)
      this.unknown.bladeLengthMetric = bladeLength.mm;
    },
    bandSpeedMinImperialChanged(item) {
      this.unknown.bandSpeedMinImperial = parseInt(item)
      this.unknown.bandSpeedMinMetric = parseInt(calculations.fpmToMpm(this.unknown.bandSpeedMinImperial))
    },
    bandSpeedMaxImperialChanged(item) {
      this.unknown.bandSpeedMaxImperial = parseInt(item)
      this.unknown.bandSpeedMaxMetric = parseInt(calculations.fpmToMpm(this.unknown.bandSpeedMaxImperial))
    },
    resetUnknownForm() {
      this.$store.dispatch("setMachine", null);
      this.$store.dispatch("setInputMachine", null);

      this.$refs.unknownForm.reset();
      this.setUnknownFormDefaults();
    },
    setUnknownFormDefaults() {
      this.unknown = JSON.parse(JSON.stringify(this.unknownDefault));
    },
    addUnknownMachine() {
      this.unknownOverlay = true;

      this.$store
        .dispatch("addUnknownMachine", {
          machine: this.unknown,
          uom: this.inputUOM,
        })
        .then(
          (response) => {
            this.unknownOverlay = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };
            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.unknownOverlay = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }
            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };
            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    setUnknownMachine(machine) {
      if (!machine) {
        return;
      }

      if (!this.unknown.manufacturerName) {
        this.unknown.manufacturerName = "Unknown";
      }

      if (!this.unknown.model) {
        this.unknown.model = "Unknown";
      }

      this.$store.dispatch("setInputMachine", machine);

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputMachine.machine")}: ${
          machine.manufacturerName
        }/${machine.model} ${this.$t("sawCalc.inputMachine.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    close() {
      this.$store.dispatch("showMachineSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      if (this.$refs.unknownForm) {
        this.$refs.unknownForm.reset();
      }
    }
  },

  mounted() {
    this.setUnknownFormDefaults();
  },
};
</script>
