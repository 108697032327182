<template>
    <v-container
        class="pa-0 px-2 pt-3"
        :class="{ 'px-6': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row>
            <v-col cols="12" class="pa-0 pb-2">
                <div class="card-text-caption">
                    {{ $t("sawCalc.inputMaterial.scaleLabel") }}
                </div>
                <div class="pl-2 card-text-description">
                    {{ item.scale ? item.scale.description : "" }}
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pa-0 pb-2">
                <div class="card-text-caption">
                    {{ $t("sawCalc.inputMaterial.hardnessLabel") }}
                </div>
                <div class="pl-2 card-text-description">
                    {{ item.hardness }}
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pa-0 pb-2">
                <div class="card-text-caption">
                    {{ $t("sawCalc.inputMaterial.surfaceConditionLabel") }}
                </div>
                <div class="pl-2 card-text-description">
                    {{ item.heavyScaleForged ? $t("sawCalc.inputMaterial.heavyScaleForgedLabel") : "None" }}
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pa-0 pb-2">
                <div class="card-text-caption">
                    {{ $t("sawCalc.inputMaterial.materialConditionLabel") }}
                </div>
                <div class="pl-2 card-text-description">
                    {{ item.htQuenched ? $t("sawCalc.inputMaterial.htQuenchedLabel") : "None" }}
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: "MaterialAjustmentsView",

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
