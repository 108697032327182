<template>
  <v-form ref="form">
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12" sm="4" class="py-0">
          <v-container class="pa-0 px-3">
            <v-row align="center" justify="center">
              <v-col cols="12">
                {{ $t("sawCalc.inputDimensions.specifyDimensions") }}
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-if="inputUOM == 0"
                  label="A, in"
                  v-model="dimensions.aImperial"
                  :rules="inputRules"
                  type="number"
                  autofocus
                >
                </v-text-field>
                <v-text-field
                  v-else
                  label="A, mm"
                  v-model="dimensions.aMetric"
                  :rules="inputRules"
                  type="number"
                  autofocus
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-if="inputUOM == 0"
                  label="B, in"
                  v-model="dimensions.bImperial"
                  :rules="inputRulesThickness"
                  type="number"
                >
                </v-text-field>
                <v-text-field
                  v-else
                  label="B, mm"
                  v-model="dimensions.bMetric"
                  :rules="inputRulesThickness"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col></v-col>
        <v-col cols="12" sm="7" align="center" justify="center" class="py-0">
          <v-container class="pa-0">
            <v-row align="center" justify="center">
              <v-col>
                <img
                  v-if="!rotated"
                  src="/images/Dimensions/angle-iron-dimensions.png"
                  width="280px"
                />
                <img
                  v-else
                  src="/images/Dimensions/angle-iron-dimensions-rotated.png"
                  width="280px"
                />
              </v-col>
              <v-col cols="12" sm="8" class="px-5 pt-0">
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputDimensions.rotateButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  @click="rotate()"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="pa-0 px-4">
        <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
          <v-btn
            depressed
            tile
            v-html="$t('sawCalc.inputDimensions.clearButton')"
            class="primary white--text full-width"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="!dirty"
            @click="resetForm()"
          ></v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
          <v-btn
            depressed
            tile
            v-html="$t('sawCalc.inputDimensions.setDimensions')"
            class="primary white--text full-width"
            :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
            :disabled="!valid"
            @click="setDimensions(dimensions)"
          ></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { calculations } from "@/helpers/Calculations";

export default {
  name: "AngleIron",

  props: {
    shape: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dimensions: {
        aImperial: null,
        aMetric: null,
        bImperial: null,
        bMetric: null,
        descriptionImperial: null,
        descriptionMetric: null,
      },
      rotated: false,
      dimensionsDefault: {
        aImperial: null,
        aMetric: null,
        bImperial: null,
        bMetric: null,
        descriptionImperial: null,
        descriptionMetric: null,
      },
      inputRules: [
        (v) =>
          (!!v && v.length > 0) ||
          this.$t("sawCalc.inputDimensions.inputRules"),
        (v) =>
          (!!v && this.validA) ||
          this.$t("sawCalc.inputDimensions.inputRulesNumber"),
      ],
      inputRulesThickness: [
        (v) =>
          (!!v && this.validB) ||
          this.$t("sawCalc.inputDimensions.inputRulesThickness"),
      ],
    };
  },

  computed: {
    ...mapGetters(["inputUOM", "resetInputs"]),
    metric() {
      return this.inputUOM > 0;
    },
    valid() {
      if (!this.dimensions) {
        return false;
      }

      return this.validA && this.validB;
    },
    validA() {
      if (!this.dimensions) {
        return false;
      }

      let a = this.metric ? this.dimensions.aMetric : this.dimensions.aImperial;

      if (a != null) {
        a = parseFloat(a);
      }

      return a != null && a > 0;
    },
    validB() {
      if (!this.dimensions) {
        return false;
      }

      let a = this.metric ? this.dimensions.aMetric : this.dimensions.aImperial;

      if (a != null) {
        a = parseFloat(a);
      }

      let b = this.metric ? this.dimensions.bMetric : this.dimensions.bImperial;

      if (b != null) {
        b = parseFloat(b);
      }

      return a != null && b != null && b > 0 && b <= a * 0.2;
    },
    dirty() {
      return (
        JSON.stringify(this.dimensions) !==
        JSON.stringify(this.dimensionsDefault)
      );
    },
  },

  methods: {
    setFormDefaults() {
      this.dimensions = JSON.parse(JSON.stringify(this.dimensionsDefault));
    },
    resetForm() {
      this.$refs.form.reset();
      this.setFormDefaults();

      this.$store.dispatch("setInputDimensions", null);
    },
    rotate() {
      this.rotated = !this.rotated;
    },
    setDimensions(dimensions) {
      if (!dimensions) {
        return;
      }

      if (this.metric) {
        dimensions.aImperial = calculations.mmToIn(dimensions.aMetric);
        dimensions.bImperial = calculations.mmToIn(dimensions.bMetric);
      } else {
        dimensions.aMetric = calculations.inToMM(dimensions.aImperial);
        dimensions.bMetric = calculations.inToMM(dimensions.bImperial);
      }

      const measurementMetric =
        (2 * (dimensions.aMetric * dimensions.bMetric) -
          Math.pow(dimensions.bMetric, 2)) /
        100;
      const measurementImperial =
        2 * dimensions.aImperial * dimensions.bImperial -
        Math.pow(dimensions.bImperial, 2);

      dimensions.descriptionImperial =
        calculations.inValue(dimensions.aImperial, 2, true) +
        " x " +
        calculations.inValue(dimensions.bImperial, 2, true);
      dimensions.descriptionMetric =
        calculations.mmValue(dimensions.aMetric, 1, true) +
        " x " +
        calculations.mmValue(dimensions.bMetric, 1, true);

      this.$store.dispatch("setInputDimensions", {
        dimensions: {
          shape: this.shape,
          dimensionDetails: dimensions,
          rotated: this.rotated,
          measurement: {
            measurementImperial: measurementImperial,
            measurementMetric: measurementMetric,
          },
        },
        uom: this.inputUOM,
      });

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputDimensions.dimensions")} ${this.$t(
          "sawCalc.inputDimensions.for"
        )}: ${this.shape.title} ${this.$t("sawCalc.inputDimensions.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    close() {
      this.$store.dispatch("showDimensionsSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.setFormDefaults();
    },
  },

  mounted() {
    this.setFormDefaults();
  },
};
</script>
