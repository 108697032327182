<template>
  <div>
    <v-dialog
      v-model="visingSelectorDialog.show"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="650px"
    >
      <v-card>
        <v-card-title class="card-title">
          <h3>
            {{ $t("sawCalc.inputVising.title") }}
          </h3>
          <v-spacer />
          <v-btn text icon color="primary" x-large @click="close">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container class="px-2 py-0">
            <v-row>
              <v-col class="py-0 pb-2">
                <v-tabs
                  v-model="selectedTab"
                  fixed-tabs
                  background-color="transparent"
                  slider-color="primary"
                  slider-size="2"
                >
                  <v-tab
                    v-for="(item, i) in tabs"
                    :key="`${i}-tab`"
                    class="selector-tab"
                    :class="{
                      'primary--text': !tabActive(item.id),
                      'secondary--text': tabActive(item.id),
                    }"
                    >{{ item.title }}</v-tab
                  >

                  <v-tab-item>
                    <v-card outlined>
                      <v-form ref="singleForm">
                        <v-card-text class="pa-0 px-3">
                          <v-container fluid class="pa-0 px-3">
                            <v-row>
                              <v-col cols="12" class="pa-0 pb-2">
                                <Single />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-form>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card outlined>
                      <v-form ref="multipleForm">
                        <v-card-text class="pa-0 px-3">
                          <v-container fluid class="pa-0 px-3">
                            <v-row>
                              <v-col cols="12" class="pa-0 pb-2">
                                <Multiple />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-form>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Single from "@/components/public/SawCalc/Selectors/Vising/Single";
import Multiple from "@/components/public/SawCalc/Selectors/Vising/Multiple";

import { mapGetters } from "vuex";

export default {
  name: "VisingSelector",

  components: {
    Single,
    Multiple,
  },

  data() {
    return {
      selectedTab: 0,
    };
  },

  computed: {
    ...mapGetters([
      "visingSelectorDialog",
      "inputUOM",
      "inputDimensions",
      "axiosSource",
      "user",
      "inputVising",
      "resetInputs"
    ]),
    tabs() {
      return [
        {
          id: 0,
          title: this.$t("sawCalc.inputVising.single"),
        },
        {
          id: 1,
          title: this.$t("sawCalc.inputVising.multiple"),
        },
      ];
    },
    dimenstionsDefined() {
      return this.inputDimensions && this.inputDimensions.shape;
    },
  },

  methods: {
    setSelectedTab() {
      this.selectedTab = 0;
    },
    tabActive(id) {
      return id == this.selectedTab;
    },
    close() {
      this.$store.dispatch("showVisingSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      if (this.$refs.singleForm) {
        this.$refs.singleForm.reset();
      }

      if (this.$refs.multipleForm) {
        this.$refs.multipleForm.reset();
      }
      
      this.setSelectedTab();
    },
  },

  mounted() {
    this.setSelectedTab();
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  padding: 0px;
}

.v-expansion-panel-header {
  padding: 0 12px 0 12px;
  min-height: 40px;
}

.list-subheader {
  text-transform: uppercase;
}
</style>
