<template>
  <div>
    <v-dialog
      v-model="materialSelectorDialog.show"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="900px"
    >
      <v-card>
        <v-form ref="selectForm">
          <v-card-title class="card-title py-0">
            <v-container class="pa-0">
              <v-row>
                <v-col class="mt-0">
                  <h3>
                    {{ $t("sawCalc.inputMaterial.title") }}
                  </h3>
                </v-col>
                <v-col cols="5" sm="4" class="pa-0">
                  <v-text-field
                    :label="$t('sawCalc.inputMaterial.search')"
                    hide-details
                    :disabled="materialsLoading"
                    @click="searchClicked"
                    @input="searchInput"
                    @keydown.enter.prevent=""
                  >
                    <v-icon slot="prepend" color="primary lighten-1"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="2" sm="1" class="pa-0">
                  <v-btn text icon color="primary" x-large @click="close">
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>

          <v-card-text class="pa-0">
            <v-container class="px-2 py-0">
              <v-row>
                <v-col class="py-0 pb-2">
                  <v-tabs
                    v-model="selectedTab"
                    fixed-tabs
                    background-color="transparent"
                    slider-color="primary"
                    slider-size="2"
                  >
                    <v-tab
                      v-for="(item, i) in tabs"
                      :key="`${i}-tab`"
                      class="selector-tab"
                      :class="{
                        'primary--text': !tabActive(item.id),
                        'secondary--text': tabActive(item.id),
                      }"
                    >
                      <v-badge
                        :color="getTabBadgeColor(item)"
                        offset-x="-2"
                        offset-y="12"
                        :content="getTabBadge(item)"
                      >
                        {{ item.title }}
                      </v-badge>
                    </v-tab>

                    <v-tab-item v-if="user && !user.admin">
                      <UserMaterial :items="userMaterials" />
                    </v-tab-item>

                    <v-tab-item>
                      <Select
                        :materialStandards="materialStandards"
                        :unsCategories="unsCategories"
                        :subCategories="subCategories"
                        :selectMaterials="selectMaterials"
                      />
                    </v-tab-item>

                    <v-tab-item>
                      <Search
                        :materialStandards="materialStandards"
                        :unsCategories="unsCategories"
                        :subCategories="subCategories"
                        :materials="materials"
                        :materialSearch="materialSearch"
                        @materialChanged="materialChanged"
                      />
                    </v-tab-item>
                  </v-tabs>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserMaterial from "@/components/public/SawCalc/Selectors/Material/UserMaterial";
import Select from "@/components/public/SawCalc/Selectors/Material/Select";
import Search from "@/components/public/SawCalc/Selectors/Material/Search";

import { mapGetters } from "vuex";

export default {
  name: "MaterialSelector",

  components: {
    UserMaterial,
    Select,
    Search,
  },

  props: {
    materialStandards: {
      type: Array,
      required: true,
      default: () => [],
    },
    unsCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    subCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    materials: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectMaterials: {
      type: Array,
      required: true,
      default: () => [],
    },
    userMaterials: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      selectedTab: 0,
      materialSearch: "",
      materialsLoading: false,
    };
  },

  computed: {
    ...mapGetters(["materialSelectorDialog", "user", "resetInputs"]),
    tabs() {
      if (this.user && !this.user.admin) {
        return [
          {
            id: 0,
            title: this.$t("sawCalc.inputMaterial.myMaterials"),
          },
          {
            id: 1,
            title: this.$t("sawCalc.inputMaterial.select"),
          },
          {
            id: 2,
            title: this.$t("sawCalc.inputMaterial.search"),
          },
        ];
      } else {
        return [
          {
            id: 0,
            title: this.$t("sawCalc.inputMaterial.select"),
          },
          {
            id: 1,
            title: this.$t("sawCalc.inputMaterial.search"),
          },
        ];
      }
    },
  },

  methods: {
    setSelectedTab() {
      if (this.user && !this.user.admin) {
        this.selectedTab = 1;
      } else {
        this.selectedTab = 0;
      }
    },
    tabActive(id) {
      return id == this.selectedTab;
    },
    getTabBadge(item) {
      let count = 0;

      if (this.user) {
        if (item.id == 0) {
          count = this.userMaterials.length;
        } else if (item.id == 1) {
          count = this.selectMaterials.length;
        } else {
          count = this.materials.length;
        }
      } else {
        if (item.id == 0) {
          count = this.selectMaterials.length;
        } else {
          count = this.materials.length;
        }
      }

      return count;
    },
    getTabBadgeColor(item) {
      let color = "transparent";

      if (this.user) {
        if (item.id == 0 && this.userMaterials.length > 0) {
          color = "rgba(0, 0, 0, 0.3)";
        } else if (item.id == 1 && this.selectMaterials.length > 0) {
          color = "rgba(0, 0, 0, 0.3)";
        } else if (item.id == 2 && this.materials.length > 0) {
          color = "rgba(0, 0, 0, 0.3)";
        }
      } else {
        if (item.id == 0 && this.selectMaterials.length > 0) {
          color = "rgba(0, 0, 0, 0.3)";
        } else if (item.id == 1 && this.materials.length > 0) {
          color = "rgba(0, 0, 0, 0.3)";
        }
      }

      return color;
    },
    searchClicked() {
      this.selectedTab = this.tabs.length - 1;

      if (!this.materialSearch || this.materialSearch.length == 0) {
        this.$store.dispatch("setMaterial", null);
      }
    },
    searchInput(text) {
      if (!text || (text.length > 0 && text.length < 2)) {
        return;
      }
      this.materialSearch = text;
      this.$store.dispatch("setMaterial", null);
    },
    materialChanged(loading) {
      this.materialsLoading = loading;
    },
    close() {
      this.$store.dispatch("showMaterialSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      this.materialSearch = "";

      if (this.$refs.selectForm) {
        this.$refs.selectForm.reset();
      }
    },
  },

  mounted() {
    this.setSelectedTab();
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  padding: 0px;
}

.v-expansion-panel-header {
  padding: 0 12px 0 12px;
  min-height: 40px;
}
</style>
