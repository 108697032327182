<template>
  <v-container class="pa-0 px-3">
    <v-row>
      <v-col class="pa-0 mr-3 ma-0 primary" style="height: 1px;"></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8" class="pa-0">
        <MaterialDetails :item="item" />
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="pa-0"
        :class="{ 'vertical-divider': $vuetify.breakpoint.mdAndUp }"
      >
        <MaterialAjustments :item="item" :scales="scales"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MaterialDetails from "@/components/public/SawCalc/Selectors/Material/MaterialDetails";
import MaterialAjustments from "@/components/public/SawCalc/Selectors/Material/MaterialAjustments";

export default {
  name: "MaterialSelectDetails",

  components: {
    MaterialDetails,
    MaterialAjustments
  },

  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    scales: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.vertical-divider {
  border-left: 1px solid lightGray;
}
</style>
