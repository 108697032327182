<template>
  <div>
    <v-dialog
      v-model="dimensionsSelectorDialog.show"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="650px"
    >
      <v-card>
        <v-card-title class="card-title">
          <h3>
            {{ $t("sawCalc.inputDimensions.title") }}
          </h3>
          <v-spacer />
          <v-btn text icon color="primary" x-large @click="close">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container class="px-2 py-0">
            <v-row>
              <v-col class="py-0 pb-2">
                <v-tabs
                  v-model="selectedTab"
                  fixed-tabs
                  center-active
                  show-arrows
                  background-color="transparent"
                  slider-color="primary"
                  slider-size="2"
                >
                  <v-tab
                    v-for="(item, i) in tabs"
                    :key="`${i}-tab`"
                    class="selector-tab"
                    :class="{
                      'primary--text': !tabActive(item.id),
                      'secondary--text': tabActive(item.id),
                    }"
                    ><img :src="item.img" width="50"
                  /></v-tab>

                  <v-tab-item>
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col>
                              <SolidRound :shape="tabs[0].shape" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col>
                              <RoundTube :shape="tabs[1].shape" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col>
                              <SolidRectangle :shape="tabs[2].shape" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col>
                              <RectangleTube :shape="tabs[3].shape" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col>
                              <IBeam
                                :shape="tabs[4].shape"
                                :iBeamGroups="iBeamGroups"
                                :iBeamStandards="iBeamStandards"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col>
                              <AngleIron :shape="tabs[5].shape" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <v-container class="py-0">
                          <v-row>
                            <v-col>
                              <Channel :shape="tabs[6].shape" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SolidRound from "@/components/public/SawCalc/Selectors/Dimensions/SolidRound";
import RoundTube from "@/components/public/SawCalc/Selectors/Dimensions/RoundTube";
import SolidRectangle from "@/components/public/SawCalc/Selectors/Dimensions/SolidRectangle";
import RectangleTube from "@/components/public/SawCalc/Selectors/Dimensions/RectangleTube";
import IBeam from "@/components/public/SawCalc/Selectors/Dimensions/IBeam";
import AngleIron from "@/components/public/SawCalc/Selectors/Dimensions/AngleIron";
import Channel from "@/components/public/SawCalc/Selectors/Dimensions/Channel";

import { mapGetters } from "vuex";

export default {
  name: "DimensionsSelector",

  components: {
    SolidRound,
    RoundTube,
    SolidRectangle,
    RectangleTube,
    IBeam,
    AngleIron,
    Channel,
  },

  data() {
    return {
      selectedTab: 0,
    };
  },

  computed: {
    ...mapGetters([
      "dimensionsSelectorDialog",
      "inputUOM",
      "inputDimensions",
      "iBeamGroups",
      "iBeamStandards",
      "axiosSource",
      "user",
    ]),
    tabs() {
      return [
        {
          id: 0,
          img: "/images/Dimensions/solid-round.png",
          shape: {
            id: 0,
            title: "solidRound",
          },
        },
        {
          id: 1,
          img: "/images/Dimensions/round-tube.png",
          shape: {
            id: 1,
            title: "roundTube",
          },
        },
        {
          id: 2,
          img: "/images/Dimensions/solid-rectangle.png",
          shape: {
            id: 2,
            title: "solidRectangle",
          },
        },
        {
          id: 3,
          img: "/images/Dimensions/rectangle-tube.png",
          shape: {
            id: 3,
            title: "rectangleTube",
          },
        },
        {
          id: 4,
          img: "/images/Dimensions/i-beam.png",
          shape: {
            id: 4,
            title: "iBeam",
          },
        },
        {
          id: 5,
          img: "/images/Dimensions/angle-iron.png",
          shape: {
            id: 5,
            title: "angleIron",
          },
        },
        {
          id: 6,
          img: "/images/Dimensions/channel.png",
          shape: {
            id: 6,
            title: "channel",
          },
        },
      ];
    },
  },

  methods: {
    tabActive(id) {
      return id == this.selectedTab;
    },
    close() {
      if (this.inputDimensions && this.inputDimensions.shape) {
        this.selectedTab = this.inputDimensions.shape.id;
      }

      this.$store.dispatch("showDimensionsSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  padding: 0px;
}

.v-expansion-panel-header {
  padding: 0 12px 0 12px;
  min-height: 40px;
}

.list-subheader {
  text-transform: uppercase;
}

.tab-image {
  background-image: url("/images/Dimensions/SolidRound.png");
  background-repeat: no-repeat;
}
</style>
