<template>
    <v-container>
        <v-row>
            <v-col align="center" justify="center">
                <img src="/images/Vising/vising-saw-top.png" />
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center" justify="center">
                <img :src="img" />
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center" justify="center">
                <img src="/images/Vising/vising-saw-bottom.png" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  name: "ViseSetup",

  props: {
    img: {
      type: String,
      required: true
    }
  }
};
</script>