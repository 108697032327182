<template>
  <v-container fluid class="py-0">
    <v-row justify="center">
      <v-col class="pa-0 pt-1 pl-1">
        <v-card flat :max-width="$vuetify.breakpoint.xs ? 390 : 360">
          <v-card-text class="pa-1 pt-0 pb-0">
            <v-container
              fluid
              class="pa-1 pt-0"
              :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
            >
              <v-row>
                <v-col class="px-2 py-0 card-title">
                  <v-row>
                    <v-col v-if="$vuetify.breakpoint.smAndUp" class="pa-0 pl-3">
                      <span
                        v-html="
                          $t(
                            'sawCalc.recommendation.title[' +
                              this.recommendationIndex +
                              ']'
                          )
                        "
                        class="pr-5 success--text"
                      />
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            @click="reset()">
                            <v-icon>mdi-restart-alert</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('sawCalc.recommendation.reset') }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col v-else class="pa-0 pb-3">
                      <v-btn class="pl-3" icon color="primary" @click="reset()">
                        <v-icon>mdi-restart-alert</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="pb-1">
                <v-col
                  cols="12"
                  sm="5"
                  class="px-2 py-0"
                  :class="{ 'pr-6': $vuetify.breakpoint.smAndDown }"
                >
                  <v-autocomplete
                    v-model="blade"
                    :label="$t('sawCalc.recommendation.blade')"
                    :items="blades"
                    item-text="bladeDescription"
                    item-value="blade"
                    dense
                    hide-details
                    @change="bladeChanged"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  class="card-text-description"
                  :class="{
                    'px-2 py-5': $vuetify.breakpoint.smAndDown,
                    'px-4 pb-0': $vuetify.breakpoint.mdAndUp,
                  }"
                >
                  {{ getSpecs(this.editedItem) }}
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="px-2 py-0"
                  :class="{ 'pr-6': $vuetify.breakpoint.smAndDown }"
                >
                  <v-autocomplete
                    v-model="tpi"
                    :label="$t('sawCalc.recommendation.tpi')"
                    :items="tpis"
                    item-text="tpi"
                    item-value="tpi"
                    dense
                    hide-details
                    @change="tpiChanged"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-row>
                    <v-col class="px-2 pt-1 pb-0">
                      <span class="card-text-caption"
                        ><span v-html="$t('sawCalc.recommendation.bandSpeed')"
                      /></span>
                      <span class="pl-2 card-text-caption-uom">{{
                        bandSpeedUOM
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-0 px-4">
                      <v-container class="pa-0">
                        <v-row>
                          <v-col class="pa-0 mx-2 control-width">
                            <v-btn
                              fab
                              dark
                              small
                              color="primary"
                              class="control-button"
                              @click="bandSpeedDecrease"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="inputUOM == 0"
                            class="pa-0 control-width"
                          >
                            <v-text-field
                              single-line
                              :value="editedItem.bandSpeedImperial"
                              class="pa-0 centered-input control-text"
                              @keypress="isNumberBandSpeed($event)"
                              
                              @change="bandSpeedChanged"
                            ></v-text-field>
                          </v-col>
                          <v-col v-else class="pa-0 control-width">
                            <v-text-field
                              single-line
                              :value="editedItem.bandSpeedMetric"
                              class="pa-0 centered-input control-text"
                              @keypress="isNumberBandSpeed($event)"
                             
                              @change="bandSpeedChanged"
                            ></v-text-field>
                          </v-col>
                          <v-col class="pa-0 control-width">
                            <v-btn
                              fab
                              dark
                              small
                              color="primary"
                              class="control-button"
                              @click="bandSpeedIncrease"
                            >
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0">
                  <v-row>
                    <v-col class="px-2 pt-1 pb-0">
                      <span class="card-text-caption"
                        ><span v-html="$t('sawCalc.recommendation.feedRate')"
                      /></span>
                      <span class="pl-2 card-text-caption-uom">{{
                        feedRateUOM
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-0 px-4">
                      <v-container class="pa-0">
                        <v-row>
                          <v-col class="pa-0 mx-2 control-width">
                            <v-btn
                              fab
                              dark
                              small
                              color="primary"
                              class="control-button"
                              @click="feedRateDecrease"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col
                            v-if="inputUOM == 0"
                            class="pa-0 control-width"
                          >
                            <v-text-field
                              single-line
                              :value="editedItem.feedRateImperial"
                              class="pa-0 centered-input control-text"
                              @input="feedRateChangedByTyping"
                              @change="feedRateChanged" 
                            ></v-text-field>
                          </v-col>
                          <v-col v-else class="pa-0 control-width">
                            <v-text-field
                              single-line
                              :value="editedItem.feedRateMetric"
                              class="pa-0 centered-input control-text"
                              @input="feedRateChangedByTyping"
                              @change="feedRateChanged"
                            ></v-text-field>
                          </v-col>
                          <v-col class="pa-0 control-width">
                            <v-btn
                              fab
                              dark
                              small
                              color="primary"
                              class="control-button"
                              @click="feedRateIncrease"
                            >
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0 pt-5 px-2">
                  <v-slider
                    v-model="overrideValue"
                    :min="overrideMin"
                    :max="overrideMax"
                    :step="overrideStep"
                    dense
                    hide-details
                    prepend-icon="mdi-speedometer-slow"
                    append-icon="mdi-speedometer"
                    :label="$t('sawCalc.recommendation.override')"
                    thumb-label
                    :disabled="!sliderEnabled"
                    @change="overrideChanged"
                  ></v-slider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0 px-3">
                  <v-row>
                    <v-col class="px-2 pt-1 pb-0">
                      <span class="card-text-caption"
                        ><span v-html="$t('sawCalc.recommendation.chipLoad')"
                      /></span>
                      <span class="pl-2 card-text-caption-uom">{{
                        chipLoadUOM
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="valid"
                      class="px-4 py-0 card-text-description"
                      >{{ getChipLoad }}</v-col
                    >
                    <v-col
                      v-else
                      class="px-4 py-0 card-text-description white--text"
                    >
                      chipLoad
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-2 pt-1 pb-0">
                      <span class="card-text-caption"
                        ><span v-html="$t('sawCalc.recommendation.cuttingRate')"
                      /></span>
                      <span class="pl-2 card-text-caption-uom">{{
                        cuttingRateUOM
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="valid"
                      class="px-4 py-0 card-text-description"
                      >{{ getCuttingRate }}</v-col
                    >
                    <v-col
                      v-else
                      class="px-4 py-0 card-text-description white--text"
                    >
                      cuttingRate
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-2 pt-1 pb-0">
                      <span class="card-text-caption"
                        ><span v-html="$t('sawCalc.recommendation.cutTime')"
                      /></span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="valid"
                      class="px-4 py-0 card-text-description"
                      >{{ editedItem.cutTimeDescription }}</v-col
                    >
                    <v-col
                      v-else
                      class="px-4 py-0 card-text-description white--text"
                    >
                      cutTime
                    </v-col>
                  </v-row>
                </v-col>
                <v-col align="center" justify="center">
                  <v-container fluid v-if="updateRequired">
                    <v-row>
                      <v-col align="left" justify="center">
                        <v-btn
                          icon
                          color="primary"
                          @click="updateRecommendation()"
                        >
                          <v-icon size="48">mdi-cached</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>

              <v-row class="pt-5">
                <v-col
                  v-if="saveAvailable"
                  cols="12"
                  :sm="smCols"
                  class="pa-0 pl-2 pb-3"
                  :class="{ 'pb-5': $vuetify.breakpoint.smAndUp }"
                >
                  <v-btn
                    block
                    depressed
                    tile
                    v-html="$t('sawCalc.recommendation.save')"
                    color="primary"
                    :x-small="$vuetify.breakpoint.mdAndDown"
                    :disabled="!reportEnabled || !user"
                    @click="createUserRecommendation()"
                  >
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  :sm="smCols"
                  class="pa-0 pl-2 pb-3"
                  :class="{ 'pb-5': $vuetify.breakpoint.smAndUp }"
                >
                  <v-btn
                    block
                    depressed
                    tile
                    v-html="$t('sawCalc.recommendation.cancel')"
                    color="primary"
                    :x-small="$vuetify.breakpoint.mdAndDown"
                    @click="cancel()"
                  ></v-btn>
                </v-col>
                <v-col
                  cols="12"
                  :sm="smCols"
                  class="pa-0 pl-2 pb-3"
                  :class="{ 'pb-5': $vuetify.breakpoint.smAndUp }"
                >
                  <v-btn
                    block
                    depressed
                    tile
                    v-html="$t('sawCalc.recommendation.update')"
                    color="primary"
                    :x-small="$vuetify.breakpoint.mdAndDown"
                    :disabled="!valid"
                    @click="update()"
                  ></v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0 px-2 ma-0">
                  <CostCalculation
                    :recommendation="recommendation"
                    :recommendationIndex="this.recommendationIndex"
                    :costCalculationOriginal="costCalculation"
                    :reset="0"
                    :update="0"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import CostCalculation from "@/components/public/SawCalc/CostCalculation";
import { mapGetters } from "vuex";

import { recommendations } from "@/constants/Recommendations";
import { calculations } from "@/helpers/Calculations";

export default {
  name: "RecommendationCustom",

  components: {
    CostCalculation,
  },

  data() {
    return {
      editedItem: {},
      reportEnabled: false,
      sliderEnabled: true,
      overrideValue: 100,
      baseBandSpeedImperial: 0,
      baseFeedRateImperial : 0,
      baseBandSpeedMetric : 0,
      baseFeedRateMetric : 0,
      baseCuttingRateImperial : 0,
      baseCuttingRateMetric : 0,
      overrideValueDefault: 100,
      baseCutTime : 0,
      updateRequired: false,
      productLines: [],
      blades: [],
      blade: null,
      selectedBlade: null,
      tpi: null,
      selectedTpi: null,
      tpis: [],
    };
  },

  computed: {
    ...mapGetters([
      "inputUOM",
      "bandSpeedUOM",
      "feedRateUOM",
      "chipLoadUOM",
      "cuttingRateUOM",
      "recommendations",
      "costCalculations",
      "user",
      "bandSpeedImperialIncrement",
      "bandSpeedMetricIncrement",
      "bandSpeedImperialMin",
      "bandSpeedMetricMin",
      "feedRateImperialIncrement",
      "feedRateMetricIncrement",
      "feedRateImperialMin",
      "feedRateMetricMin",
      "feedRateImperialMax",
      "feedRateMetricMax",
      "overrideMin",
      "overrideMax",
      "overrideStep",
      "productLinesShort",
      "userRecommendations",
      "bwlRefs",
    ]),
    metric() {
      return this.inputUOM > 0;
    },
    recommendationIndex() {
      return recommendations.Custom;
    },
    recommendation() { //getting data for updation
      return this.recommendations[this.recommendationIndex];
    },
   
    getChipLoad() {
      if (this.metric) {
        return this.editedItem.chipLoadMetric;
      } else {
        return this.editedItem.chipLoadImperial;
      }
    },
    getCuttingRate() {
      if (this.metric) {
        return this.editedItem.cuttingRateMetric;
      } else {
        return this.editedItem.cuttingRateImperial;
      }
    },
    valid() {
      
      if (this.metric) {

        return (
          this.editedItem &&
          this.editedItem.bladeDescription &&
          this.editedItem.specsMetric &&
          this.editedItem.bandSpeedMetric &&
          this.editedItem.feedRateMetric &&
          this.editedItem.chipLoadMetric &&
          this.editedItem.cuttingRateMetric &&
          this.editedItem.cutTimeDescription
        );
      } else {
        return (
          this.editedItem &&
          this.editedItem.bladeDescription &&
          this.editedItem.specsImperial &&
          this.editedItem.bandSpeedImperial &&
          this.editedItem.feedRateImperial &&
          this.editedItem.chipLoadImperial &&
          this.editedItem.cuttingRateImperial &&
          this.editedItem.cutTimeDescription
        );
      }
    },
    costCalculation() {
      return this.costCalculations[this.recommendationIndex];
    },
    saveAvailable() {
      return this.user && !this.user.admin;
    },
    smCols() {
      if (this.saveAvailable) {
        return 4;
      } else {
        return 6;
      }
    },
  },

  methods: {
     isNumberBandSpeed(evt){
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
    },
    getProductLines() {
      this.productLines = [];

      let productSpecs = {};

      if (this.metric) {
        productSpecs = this.getProductSpecs(
          { specsMetric: this.recommendation.specsMetric },
          true
        );
      } else {
        productSpecs = this.getProductSpecs(
          { specsImperial: this.recommendation.specsImperial },
          false
        );
      }

      if (
        productSpecs.width == undefined ||
        productSpecs.width == null ||
        productSpecs.thickness == undefined ||
        productSpecs.thickness == null
      ) {
        return;
      }

      let bwlRefs;

      if (this.metric) {
        bwlRefs = this.bwlRefs.filter(
          (item) =>
            item.widthMetric == productSpecs.width &&
            item.gaugeMetric == productSpecs.thickness
        );
      } else {
        bwlRefs = this.bwlRefs.filter(
          (item) =>
            item.widthImperial == productSpecs.width &&
            item.gaugeImperial == productSpecs.thickness
        );
      }
      
      for (let i = 0; i < bwlRefs.length; i++) {
        const bwlRef = bwlRefs[i];

        let productLines = this.productLinesShort.filter(
          (item) =>
            item["bwl" + bwlRef.bwl] != null && item["bwl" + bwlRef.bwl] == true
        );

        if (productLines.length > 0) {
          this.productLines = this.productLines.concat(productLines);
        }
      }

      this.updateBlades();
    },
    getProductSpecs(item, metric) {
      const productSpecs = {};
      let specs = "";

      let metricParam = this.metric;

      if (metric != undefined) {
        metricParam = metric;
      }

      if (metricParam) {
        specs = item.specsMetric;
      } else {
        specs = item.specsImperial;
      }

      if (specs != undefined && specs.length > 0) {
        const delimiter = "x";
        const params = specs.split(delimiter);

        if (params.length == 3) {
          productSpecs.width = params[0].trim();
          productSpecs.thickness = params[1].trim();
          productSpecs.tpi = params[2].trim();
          productSpecs.description = `${productSpecs.width} ${delimiter} ${productSpecs.thickness}`;
        }
      }

      return productSpecs;
    },
    getSpecs(item, metric) {
      return this.getProductSpecs(item, metric).description;
    },
    updateBlades() {
      let list = [];

      if (this.productLines == undefined || this.productLines.length == 0) {
        return [];
      }

      list = this.productLines.map((o) => ({
        blade: o.productType,
        bladeDescription: o.productTypeDescription,
      }));

      let myMap = new Map();

      list = list.filter((el) => {
        const val = myMap.get(el.blade);
        if (val) {
          return false;
        }
        myMap.set(el.blade, el.bladeDescription);
        return true;
      });

      list.sort(function(a, b) {
        if (!a.bladeDescription || !b.bladeDescription) {
          return 0;
        }

        var x = a.bladeDescription.toLowerCase();
        var y = b.bladeDescription.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      this.blades = list;

      this.reset();
    },
    updateTpi(blade) {
      let list = [];

      if (this.productLines == undefined || this.productLines.length == 0) {
        return [];
      }

      const lst = this.productLines.filter(
        (item) => item.productType == blade.blade
      );

      list = lst.map((o) => ({
        tpi: o.tpi,
        orderInList: o.orderInList,
      }));

      let myMap = new Map();

      list = list.filter((el) => {
        const val = myMap.get(el.tpi);
        if (val) {
          return false;
        }
        myMap.set(el.tpi, el.orderInList);
        return true;
      });

      list.sort(function(a, b) {
        if (!a.tpi || !b.tpi) {
          return 0;
        }

        var x = parseInt(a.orderInList);
        var y = parseInt(b.orderInList);
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      this.tpis = list;

      const tpi = this.getProductSpecs(this.editedItem, false).tpi;

      let selected = this.tpis.find((item) => item.tpi == tpi);

      this.selectedTpi = selected;

      if (selected != undefined) {
        this.tpi = selected.tpi;
      }
    },
    bladeChanged(b) {
      const selected = this.blades.find(({ blade }) => blade == b);

      if (
        this.selectedBlade != undefined &&
        selected != undefined &&
        this.selectedBlade.blade != selected.blade
      ) {
        this.editedItem.blade = selected.blade;
        this.editedItem.bladeDescription = selected.bladeDescription;

        this.paramChanged();
      }

      this.selectedBlade = selected;
      this.updateTpi(selected);
    },
    tpiChanged(t) {
      const selected = this.tpis.find(({ tpi }) => tpi === t);
      
      if (
        this.selectedTpi != undefined &&
        selected != undefined &&
        this.selectedTpi.tpi != selected.tpi
      ) {
        this.editedItem.specsImperial =
          this.getSpecs(this.editedItem, false) + " x " + selected.tpi;
        this.editedItem.specsMetric =
          this.getSpecs(this.editedItem, true) + " x " + selected.tpi;

        this.paramChanged();
      }

      this.selectedTpi = selected;
    },
    bandSpeedChanged(val) {
      this.paramChanged();
      this.updateBandSpeed(this.metric, val);
    },
    bandSpeedDecrease() {
      this.paramChanged();

      let value;

      if (this.metric) {
        value =
          parseInt(this.editedItem.bandSpeedMetric) -
          this.bandSpeedMetricIncrement;
      } else {
        value =
          parseInt(this.editedItem.bandSpeedImperial) -
          this.bandSpeedImperialIncrement;
      }
      this.updateBandSpeed(this.metric, value);
    },
    bandSpeedIncrease() {
      this.paramChanged();

      let value;

      if (this.metric) {
        value =
          parseInt(this.editedItem.bandSpeedMetric) +
          this.bandSpeedMetricIncrement;
      } else {
        value =
          parseInt(this.editedItem.bandSpeedImperial) +
          this.bandSpeedImperialIncrement;
      }
      this.updateBandSpeed(this.metric, value);
    },
    updateBandSpeed(metric, value) {
      let machineInfo = null;
      
      if (!metric) {
          if (value < this.bandSpeedImperialMin) {
            value = this.bandSpeedImperialMin;
          }

          if (value > this.bandSpeedImperialMax) {
            value = this.bandSpeedImperialMax;
          }
      }else{
          if (value < this.bandSpeedMetricMin) {
            value = this.bandSpeedMetricMin;
          }

          if (value > this.bandSpeedMetricMax) {
            value = this.bandSpeedMetricMax;
          }
      }
      
      try {
          machineInfo = window.localStorage.getItem("machineMapsData");
          if (machineInfo !==null) {
             machineInfo = JSON.parse(machineInfo);
             if (machineInfo !==null) {
                if (!metric) {
                  if (value < machineInfo.bandSpeedMinImperial) {
                    value = machineInfo.bandSpeedMinImperial;
                  }

                  if (value > machineInfo.bandSpeedMaxImperial) {
                    value = machineInfo.bandSpeedMaxImperial;
                  }
              }else{
                  if (value < machineInfo.bandSpeedMinMetric) {
                    value = machineInfo.bandSpeedMinMetric;
                  }

                  if (value > machineInfo.bandSpeedMaxMetric) {
                    value = machineInfo.bandSpeedMaxMetric;
                  }
                }
             } 
          }
      } catch (error) {
         // eslint-disable-next-line
      }

      value = parseInt(value);
      if (!metric) {
        this.editedItem.bandSpeedImperial = value;
        this.editedItem.bandSpeedMetric = parseInt(
          calculations.fpmToMpm(value)
        );
      } else {
       
        this.editedItem.bandSpeedMetric = value;
        this.editedItem.bandSpeedImperial = parseInt(
          calculations.mpmToFpm(value)
        );
      }
    },
    feedRateChanged(val) {
      this.paramChanged(val);
      this.updateFeedRate(this.metric, val);
    },
    feedRateChangedByTyping(val){
        this.paramChanged();
        this.updateFeedRateByTyping(this.metric, val);
    },
    feedRateDecrease() {
      this.paramChanged();

      let value;

      if (this.metric) {
        value =
          parseFloat(this.editedItem.feedRateMetric) -
          this.feedRateMetricIncrement;
      } else {
        value =
          parseFloat(this.editedItem.feedRateImperial) -
          this.feedRateImperialIncrement;
      }

      this.updateFeedRate(this.metric, value);
    },
    feedRateIncrease() {
      this.paramChanged();

      let value;

      if (this.metric) {
        value =
          parseFloat(this.editedItem.feedRateMetric) +
          this.feedRateMetricIncrement;
      } else {
        value =
          parseFloat(this.editedItem.feedRateImperial) +
          this.feedRateImperialIncrement;
      }

      this.updateFeedRate(this.metric, value);
    },
    updateFeedRateByTyping(metric, value){
      if (!value) {
          value = 0;
      }
      if (metric) {
        
        if (value > this.feedRateMetricMax) {
          value = this.feedRateMetricMax;
        }

        if (isNaN(value)) {
          value = 0
        }
        
        this.editedItem.feedRateMetric = parseInt(value);
        this.editedItem.feedRateImperial = calculations.mmToIn(value);
      } else {
        if (value > this.feedRateImperialMax) {
          value = this.feedRateImperialMax;
        }

        if (isNaN(value)) {
          value = 0
        }
        this.editedItem.feedRateImperial = parseFloat(value);
        this.editedItem.feedRateMetric = parseInt(calculations.inToMM(value));
      }

    },
    updateFeedRate(metric, value) {
      
      if (!value) {
          value = 0;
      }
      if (metric) {
        if (value < this.feedRateMetricMin) {
          value = this.feedRateMetricMin; //1.27
        }

        if (value > this.feedRateMetricMax) {
          value = this.feedRateMetricMax;
        }

        if (isNaN(value)) {
          value = 0
        }
        
        this.editedItem.feedRateMetric = parseInt(value).toFixed(2);
        this.editedItem.feedRateImperial = calculations.mmToIn(value);
      } else {
        if (value < this.feedRateImperialMin) {
            value = this.feedRateImperialMin; //0.05
        }

        if (value > this.feedRateImperialMax) {
           value = this.feedRateImperialMax;
        }

        if (isNaN(value)) {
          value = 0
        }
        this.editedItem.feedRateImperial = parseFloat(value).toFixed(2);
        this.editedItem.feedRateMetric = parseInt(calculations.inToMM(value));
      }
    },
    updateCuttingRate(metric, value) {
      if (!metric) {
        this.editedItem.cuttingRateImperial = parseFloat(value).toFixed(2);
        this.editedItem.cuttingRateMetric = calculations.sqInToSqCM(value);
      } else {
        this.editedItem.cuttingRateMetric = parseFloat(value).toFixed(1);
        this.editedItem.cuttingRateImperial = calculations.sqCMToSqIn(value);
      }
    },
    overrideChanged(val) {
      //const override = parseFloat(val) / this.overrideValueDefault; //old code
      const override = parseFloat(val);
      if (this.metric) {
        this.updateBandSpeed(
          this.metric,
          this.baseBandSpeedMetric * override,
          //this.editedItem.bandSpeedMetric * override // old code
        );
        this.updateFeedRate(
          this.metric,
          this.baseFeedRateMetric * override,
          //this.editedItem.feedRateMetric * override // old code
        );
        this.updateCuttingRate(
          this.metric,
          this.baseCuttingRateMetric * override,
          //this.editedItem.cuttingRateMetric * override //old code
        );
      } else {
        this.updateBandSpeed(
          this.metric,
          this.baseBandSpeedImperial * override,
          //this.editedItem.bandSpeedImperial * override //old code
        );
        this.updateFeedRate(
          this.metric,
          this.baseFeedRateImperial * override,
          //this.editedItem.feedRateImperial * override //old code
        );
        this.updateCuttingRate(
          this.metric,
          this.baseCuttingRateImperial * override,
          //this.editedItem.cuttingRateImperial * override //old code
        );
      }

      this.editedItem.cutTime = parseInt(
          this.baseCutTime / (parseFloat(override) / this.overrideValueDefault)
      );

      /*this.editedItem.cutTime = parseInt(
          this.recommendation.cutTime / (parseFloat(override) / this.overrideValueDefault) //old code
      );*/

      this.editedItem.cutTimeDescription = calculations.secToTime(
        this.editedItem.cutTime
      );

      this.$store.dispatch("updateCostCalculation", {
        cutTime: this.editedItem.cutTime,
        costCalculation: this.costCalculation,
        recommendationIndex: recommendations.Custom,
      })
    },
    paramChanged() {
      this.sliderEnabled = false;
      this.editedItem.chipLoadImperial = null;
      this.editedItem.chipLoadMetric = null;
      this.editedItem.cuttingRateImperial = null;
      this.editedItem.cuttingRateMetric = null;
      this.editedItem.cutTime = null;
      this.editedItem.cutTimeDescription = null;

      this.updateRequired = true;

      this.overrideValue = this.overrideValueDefault;

      this.validateControls();
    },
    createUserRecommendation() {
      const form = {
        recommendation: this.recommendation,
        custom: this.getCustomSettings(),
        costCalculation: this.costCalculation,
      };

      this.$store.dispatch("createUserRecommendation", form).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    getCustomSettings() {
      const override = this.recommendation.blade == this.selectedBlade.blade && 
        this.getProductSpecs(this.recommendation, false).tpi == this.selectedTpi.tpi;

      const bandSpeedOverride = override ? parseInt(this.editedItem.bandSpeedImperial * 100 / this.recommendation.bandSpeedImperial): 100;
      const feedRateSpeedOverride = override ? parseInt(this.editedItem.feedRateImperial * 100 / this.recommendation.feedRateImperial) : 100;
      
      const custom = {
        bandSpeedOverride: bandSpeedOverride,
        feedRateSpeedOverride: feedRateSpeedOverride,
        productTypeOverride: this.selectedBlade.blade,
        tpiOverride: this.selectedTpi.tpi,
        recommendation: this.editedItem,
        costCalculation: this.costCalculation,
      }

      return custom;
    },
    validateControls() {
      const enabled = this.editedItem != null && this.editedItem.cutTime > 0;
      this.reportEnabled = enabled;
    },
    reset() {
      /*****creating default base values******/

      try {
          let getIndex = window.localStorage.getItem("recommendationIndex");
          let masterValuesData = window.localStorage.getItem("recommendationDataVal");
          if (masterValuesData !=null) {
              masterValuesData = JSON.parse(masterValuesData);
              let result = masterValuesData.filter(entry => entry.index == getIndex);

              if (result.length > 0) {
                  this.baseBandSpeedImperial = result[0].bandSpeedImperial / this.overrideValueDefault;
                  this.baseBandSpeedMetric = result[0].bandSpeedMetric / this.overrideValueDefault;
                  this.baseFeedRateImperial = result[0].feedRateImperial / this.overrideValueDefault;
                  this.baseFeedRateMetric  = result[0].feedRateMetric / this.overrideValueDefault;
                  this.baseCuttingRateImperial  = result[0].cuttingRateImperial / this.overrideValueDefault;
                  this.baseCuttingRateMetric  = result[0].cuttingRateMetric / this.overrideValueDefault;
                  this.baseCutTime = result[0].cutTime;
              } else {
                  this.baseBandSpeedImperial = this.recommendation.bandSpeedImperial / this.overrideValueDefault;
                  this.baseBandSpeedMetric = this.recommendation.bandSpeedMetric / this.overrideValueDefault;
                  this.baseFeedRateImperial = this.recommendation.feedRateImperial / this.overrideValueDefault;
                  this.baseFeedRateMetric  = this.recommendation.feedRateMetric / this.overrideValueDefault;
                  this.baseCuttingRateImperial  = this.recommendation.cuttingRateImperial / this.overrideValueDefault;
                  this.baseCuttingRateMetric  = this.recommendation.cuttingRateMetric / this.overrideValueDefault;
                  this.baseCutTime = this.recommendation.cutTime;
              }
              
          }else{
              this.baseBandSpeedImperial = this.recommendation.bandSpeedImperial / this.overrideValueDefault;
              this.baseBandSpeedMetric = this.recommendation.bandSpeedMetric / this.overrideValueDefault;
              this.baseFeedRateImperial = this.recommendation.feedRateImperial / this.overrideValueDefault;
              this.baseFeedRateMetric  = this.recommendation.feedRateMetric / this.overrideValueDefault;
              this.baseCuttingRateImperial  = this.recommendation.cuttingRateImperial / this.overrideValueDefault;
              this.baseCuttingRateMetric  = this.recommendation.cuttingRateMetric / this.overrideValueDefault;
              this.baseCutTime = this.recommendation.cutTime;
          }
          
      } catch (error) {
         // eslint-disable-next-line
      }

    
      /*****End of creating default base values******/

      let defualtOverrideVal = this.overrideValueDefault;

      try { 
          //getting last override value
          let lastOverrideValue = window.localStorage.getItem("lastOverrideValue");
          if (lastOverrideValue !=null) {
             defualtOverrideVal = lastOverrideValue;
          }
      } catch (error) {
          // eslint-disable-next-line
      }
     
      this.editedItem = Object.assign({}, this.recommendation);
      this.sliderEnabled = true;
      this.updateRequired = false;
      //this.overrideValue = this.overrideValueDefault;
      this.overrideValue = defualtOverrideVal;

      const selected = this.blades.find(
        (item) => item.blade == this.editedItem.blade
      );

      this.selectedBlade = selected;

      if (selected != undefined) {
        this.blade = selected.blade;
      }

      this.bladeChanged(selected.blade);

      this.validateControls();
      this.$store.dispatch("updateCostCalculation", {
        cutTime: this.editedItem.cutTime,
        costCalculation: this.costCalculation,
        recommendationIndex: recommendations.Custom,
      })
    },
   
    updateRecommendation() {
      let bandSpeedOverride = 100;
      let isExists = null;
      const delimiterInitial = "x";
      
      try {
          isExists = window.localStorage.getItem("initialCustomizationVal");
          
          if (isExists !==null) {
              isExists = JSON.parse(isExists)  
              if (isExists !==null) {
                  let iniSpec = isExists.specsImperial;
                  if (this.metric) {
                      iniSpec = isExists.specsMetric;
                  }
                  const paramsInitial = iniSpec.split(delimiterInitial);
                  if (paramsInitial.length == 3) {
                      let initialTpi = paramsInitial[2].trim();
                      
                      if (initialTpi === this.tpi && isExists.blade === this.blade) {
                          if (this.metric && this.editedItem.bandSpeedMetric == isExists.bandSpeedMetric) {
                              this.editedItem.bandSpeedMetric = isExists.bandSpeedMetric;
                              this.recommendation.bandSpeedMetric = isExists.bandSpeedMetric;
                          } else if(this.metric == false && this.editedItem.bandSpeedImperial == isExists.bandSpeedImperial){
                              this.editedItem.bandSpeedImperial = isExists.bandSpeedImperial;
                              this.recommendation.bandSpeedImperial = isExists.bandSpeedImperial;
                          }

                          if (this.metric && this.editedItem.feedRateMetric == isExists.feedRateMetric) {
                              this.editedItem.feedRateMetric = isExists.feedRateMetric;
                              this.recommendation.feedRateMetric = isExists.feedRateMetric;
                          } else if(this.metric == false && this.editedItem.feedRateImperial == isExists.feedRateImperial){
                              this.editedItem.feedRateImperial =  isExists.feedRateImperial;
                              this.recommendation.feedRateImperial = isExists.feedRateImperial;
                          }
                      } 
                  }
              }
          }
      } catch (error) {
          // eslint-disable-next-line
      }

      //return false;
      if (this.metric) {
        bandSpeedOverride = parseInt(
          100 *
            (this.editedItem.bandSpeedMetric /
              this.recommendation.bandSpeedMetric)
        );
      } else {
        bandSpeedOverride = parseInt(
          100 *
            (this.editedItem.bandSpeedImperial /
              this.recommendation.bandSpeedImperial)
        );
      }

      let feedRateOverride = 100;

      if (this.metric) {
        feedRateOverride = parseInt(
          100 *
            (this.editedItem.feedRateMetric /
              this.recommendation.feedRateMetric)
        );
      } else {
        feedRateOverride = parseInt(
          100 *
            (this.editedItem.feedRateImperial /
              this.recommendation.feedRateImperial)
        );
      }
     
      const custom = {
        bandSpeedOverride: bandSpeedOverride,
        feedRateSpeedOverride: feedRateOverride,
        productTypeOverride: this.blade,
        tpiOverride: this.tpi,
      };
      this.$store.dispatch("getRecommendationCustom", custom).then(
        (response) => {
          const recommendation = response.recommendation;

          if (recommendation != undefined && recommendation != null) {
            this.sliderEnabled = true;

            this.editedItem.specsMetric = recommendation.SpecsMetric;
            this.editedItem.specsImperial = recommendation.SpecsImperial;
            this.editedItem.bandSpeedImperial = recommendation.BandSpeedImperial;
            this.editedItem.bandSpeedMetric = recommendation.BandSpeedMetric;
            this.editedItem.feedRateImperial = recommendation.FeedRateImperial;
            this.editedItem.feedRateMetric = recommendation.FeedRateMetric;
            this.editedItem.chipLoadImperial = recommendation.ChipLoadImperial;
            this.editedItem.chipLoadMetric = recommendation.ChipLoadMetric;
            this.editedItem.cuttingRateImperial =
              recommendation.CuttingRateImperial;
            this.editedItem.cuttingRateMetric =
              recommendation.CuttingRateMetric;
            this.editedItem.cutTime = recommendation.CutTime;
            this.editedItem.cutTimeDescription = calculations.secToTime(
              recommendation.CutTime
            );
            
            this.updateRequired = false;

            this.validateControls();
          }
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    cancel() {
      this.$store.dispatch("closeCustomRecommendation");
    },
    update() {
      try { 
          //setting new customization
          window.localStorage.setItem("lastOverrideValue", this.overrideValue);
      } catch (error) {
          // eslint-disable-next-line
      }
      this.$store.dispatch("updateFromCustom", {
        recommendation: this.editedItem,
        custom: this.getCustomSettings(),
        costCalculation: this.costCalculation,
      });
    },
  },

  mounted() {
    this.getProductLines();
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  max-height: 30px;
  min-height: 23px;
}

.theme--light.v-card.v-card--outlined {
  border-width: 0px;
}

.select-inputs {
  font-family: map-get($theme, font-family-secondary), sans-serif;
  font-weight: map-get($theme, font-weight-primary-navigation);
  font-size: map-get($theme, font-size-secondary);
  color: map-get($theme, text-color);
}

.control-width {
  max-width: 50px;
}

.control-button {
  max-height: 38px;
}

.control-text {
  width: 40px;
  height: 10px;
  font-weight: map-get($theme, font-weight-primary-navigation);
}
</style>
