<template>
  <div>
    <v-dialog
      v-model="machineSelectorDialog.show"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="750px"
    >
      <v-card>
        <v-card-title class="card-title">
          <h3>
            {{ $t("sawCalc.inputMachine.title") }}
          </h3>
          <v-spacer />
          <v-btn text icon color="primary" x-large @click="close">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container class="px-2 py-0">
            <v-row>
              <v-col class="py-0 pb-2">
                <v-tabs
                  v-model="selectedTab"
                  fixed-tabs
                  background-color="transparent"
                  slider-color="primary"
                  slider-size="2"
                >
                  <v-tab
                    v-for="(item, i) in tabs"
                    :key="`${i}-tab`"
                    class="selector-tab"
                    :class="{
                      'primary--text': !tabActive(item.id),
                      'secondary--text': tabActive(item.id),
                    }"
                  >
                    <v-badge
                      :color="getTabBadgeColor(item)"
                      offset-x="-2"
                      offset-y="12"
                      :content="getTabBadge(item)"
                    >
                      {{ item.title }}
                    </v-badge>
                  </v-tab>

                  <v-tab-item v-if="user && !user.admin">
                    <UserMachine :items="userMachines" />
                  </v-tab-item>

                  <v-tab-item>
                    <Select
                      :manufacturers="manufacturers"
                      :machines="machines"
                    />
                  </v-tab-item>

                  <v-tab-item>
                    <UnknownImperial
                      v-if="!metric"
                      :bladeWidthMetricList="bladeWidthMetricList"
                      :bladeWidthImperialList="bladeWidthImperialList"
                      :bladeThicknessMetricList="bladeThicknessMetricList"
                      :bladeThicknessImperialList="bladeThicknessImperialList"
                      :bladeLengthFtList="bladeLengthFtList"
                      :bladeLengthInList="bladeLengthInList"
                      :bladeLengthFrList="bladeLengthFrList"
                      :key="unknownMachineImperialKey"
                    />
                    <UnknownMetric
                      v-else
                      :bladeWidthMetricList="bladeWidthMetricList"
                      :bladeWidthImperialList="bladeWidthImperialList"
                      :bladeThicknessMetricList="bladeThicknessMetricList"
                      :bladeThicknessImperialList="bladeThicknessImperialList"
                      :bladeLengthMetricList="bladeLengthMetricList"
                      :key="unknownMachineMetricKey"
                    />
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserMachine from "@/components/public/SawCalc/Selectors/Machine/UserMachine";
import Select from "@/components/public/SawCalc/Selectors/Machine/Select";
import UnknownImperial from "@/components/public/SawCalc/Selectors/Machine/UnknownImperial";
import UnknownMetric from "@/components/public/SawCalc/Selectors/Machine/UnknownMetric";

import { mapGetters } from "vuex";

export default {
  name: "MachineSelector",

  components: {
    UserMachine,
    Select,
    UnknownImperial,
    UnknownMetric,
  },

  props: {
    manufacturers: {
      type: Array,
      required: true,
      default: () => [],
    },
    machines: {
      type: Array,
      required: true,
      default: () => [],
    },
    userMachines: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeWidthImperialList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeWidthMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeThicknessImperialList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeThicknessMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthFtList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthInList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthFrList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      selectedTab: 0,
    };
  },

  computed: {
    ...mapGetters([
      "machineSelectorDialog",
      "inputUOM",
      "user",
      "unknownMachineImperialKey",
      "unknownMachineMetricKey",
    ]),
    metric() {
      return this.inputUOM > 0;
    },
    tabs() {
      if (this.user && !this.user.admin) {
        return [
          {
            id: 0,
            title: this.$t("sawCalc.inputMachine.myMachines"),
          },
          {
            id: 1,
            title: this.$t("sawCalc.inputMachine.select"),
          },
          {
            id: 2,
            title: this.$t("sawCalc.inputMachine.unknown"),
          },
        ];
      } else {
        return [
          {
            id: 0,
            title: this.$t("sawCalc.inputMachine.select"),
          },
          {
            id: 1,
            title: this.$t("sawCalc.inputMachine.unknown"),
          },
        ];
      }
    },
  },

  methods: {
    setSelectedTab() {
      if (this.user && !this.user.admin) {
        this.selectedTab = 1;
      } else {
        this.selectedTab = 0;
      }
    },
    tabActive(id) {
      return id == this.selectedTab;
    },
    getTabBadge(item) {
      let count = 0;

      if (this.user) {
        if (item.id == 0) {
          count = this.userMachines.length;
        } else if (item.id == 1) {
          count = this.machines.length;
        }
      } else {
        if (item.id == 0) {
          count = this.userMachines.length;
        } else {
          count = this.machines.length;
        }
      }

      return count;
    },
    getTabBadgeColor(item) {
      let color = "transparent";

      if (this.user) {
        if (item.id == 0 && this.userMachines.length > 0) {
          color = "rgba(0, 0, 0, 0.3)";
        } else if (item.id == 1 && this.machines.length > 0) {
          color = "rgba(0, 0, 0, 0.3)";
        }
      } else {
        if (item.id == 0 && this.machines.length > 0) {
          color = "rgba(0, 0, 0, 0.3)";
        }
      }

      return color;
    },
    close() {
      this.$store.dispatch("showMachineSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  mounted() {
    this.setSelectedTab();
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  padding: 0px;
}

.v-expansion-panel-header {
  padding: 0 12px 0 12px;
  min-height: 40px;
}
</style>
