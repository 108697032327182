<template>
  <v-container fluid class="py-0">
    <v-row justify="center" @click="showSelector">
      <v-col class="pa-0">
        <v-card flat 
          :class="{
            'card-input': selectedRecommendationForCustom == null,
            'card-disabled': selectedRecommendationForCustom != null,
          }">
          <v-card-text class="pa-2">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col
                  class="px-2 py-0"
                  :class="{
                    'title-ready': inputVising.arrangement != null,
                    'title-not-ready': inputVising.arrangement == null,
                    'card-disabled': selectedRecommendationForCustom != null,
                  }"
                  ><h4>{{ $t("sawCalc.inputVising.title") }}</h4></v-col
                >
              </v-row>
              <v-row v-if="visingReady">
                <v-col class="px-2 pt-1 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.inputVising.quantity')"
                /></v-col>
              </v-row>
              <v-row v-if="visingReady">
                <v-col 
                  class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="getQuantityDescription" />
                </v-col>
              </v-row>
              <v-row v-if="visingReady">
                <v-col class="px-2 pt-1 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.inputVising.arrangement')"
                /></v-col>
              </v-row>
              <v-row v-if="visingReady">
                <v-col 
                  class="px-4 py-0 card-text-description">
                  <span v-html="getArrangementDescription"
                /></v-col>
              </v-row>
              <v-row v-else>
                <v-col class="px-2 pt-1 pb-0 card-text-error"
                  ><span v-html="$t('sawCalc.inputVising.errorNotLoaded')"
                /></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InputVising",

  computed: {
    ...mapGetters([
      "inputVising",
      "visingReady",
      "selectedRecommendationForCustom",
    ]),
    getQuantityDescription() {
      if (this.inputVising && this.inputVising.quantity) {
        return this.$t(`sawCalc.inputVising.${this.inputVising.quantity.description}`)
      } else {
        return "";
      }
    },
    getArrangementDescription() {
      if (this.inputVising && this.inputVising.arrangement) {
        const separator = " "

        if (this.inputVising.arrangement.description != undefined && this.inputVising.arrangement.description.length > 0) {
          const arrangement = this.inputVising.arrangement.description.split(separator);
          
          if (arrangement.length > 0){
            arrangement[0] = this.$t(`sawCalc.inputVising.${arrangement[0]}`)
          }

          return arrangement.join(separator)
        } else {
          return ""
        }
      } else {
        return "";
      }
    },
  },

  methods: {
    showSelector() {
      if (this.visingReady && this.selectedRecommendationForCustom == null) {
        this.$store.dispatch("showVisingSelectorDialog", true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-input {
  cursor: pointer;
}
.card-disabled {
  color: map-get($theme, footer-link-color);
  cursor: not-allowed;
}
</style>