<template>
  <v-container fluid class="px-5 py-1">
    <MachineSelector
      :manufacturers="manufacturers"
      :machines="machines"
      :userMachines="userMachines"
      :bladeWidthImperialList="bladeWidthImperialList"
      :bladeWidthMetricList="bladeWidthMetricList"
      :bladeThicknessImperialList="bladeThicknessImperialList"
      :bladeThicknessMetricList="bladeThicknessMetricList"
      :bladeLengthFtList="bladeLengthFtList"
      :bladeLengthInList="bladeLengthInList"
      :bladeLengthFrList="bladeLengthFrList"
      :bladeLengthMetricList="bladeLengthMetricList"
    />
    <MaterialSelector
      :materialStandards="materialStandards"
      :unsCategories="unsCategories"
      :subCategories="subCategories"
      :materials="materials"
      :selectMaterials="selectMaterials"
      :userMaterials="userMaterials"
    />
    <DimensionsSelector />
    <VisingSelector />
    <ConditionsSelector />

    <v-row>
      <v-col class="pa-0 ma-0 primary" style="height: 2px;"></v-col>
    </v-row>
    <v-row justify="center">
      <v-col :cols="inputsCols" v-if="inputsCols > 0" class="pa-0">
        <InputUOM />
        <InputMachine />
        <InputMaterial />
        <InputDimensions />
        <InputVising />
        <InputConditions />
        <InputCurrency />

        <v-row>
          <v-col class="pa-0 pt-3">
            <v-container
              fluid
              class="pa-0"
              :class="{
                'controls-small-max': $vuetify.breakpoint.mdAndDown,
                'controls-large-max': $vuetify.breakpoint.lgAndUp,
              }"
            >
              <v-row>
                <v-col class="pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="clear()">
                        <v-icon>mdi-restart-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('sawCalc.navBar.clear') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col class="pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!reportEnabled"
                        @click="getRecommendationReport()">
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('sawCalc.navBar.print') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="user" class="pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!reportEnabled"
                        @click="emailRecommendationReport()">
                        <v-icon>mdi-email</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('sawCalc.navBar.email') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col class="pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="refresh()">
                        <v-icon>mdi-cached</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('sawCalc.navBar.refresh') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>

      <v-col :cols="recommendationsCols" class="pa-0">
        <v-container fluid class="px-3 py-0">
          <v-row v-if="$vuetify.breakpoint.xs">
            <v-col justify="center" align="center" class="pa-0 pt-2">
              <v-btn-toggle
                depressed
                tile
                dark
                mandatory
                :value="selectedRecommendation"
                @change="recommendationChanged"
              >
                <v-btn
                  v-show="recommendationSelectBtnVisible(performance)"
                  color="primary"
                  v-html="
                    $t('sawCalc.recommendation.titleShort[' + performance + ']')
                  "
                  x-small
                ></v-btn>
                <v-btn
                  v-show="recommendationSelectBtnVisible(highPerformance)"
                  color="primary"
                  v-html="
                    $t(
                      'sawCalc.recommendation.titleShort[' +
                        highPerformance +
                        ']'
                    )
                  "
                  x-small
                ></v-btn>
                <v-btn
                  v-show="recommendationSelectBtnVisible(extremePerformance)"
                  color="primary"
                  v-html="
                    $t(
                      'sawCalc.recommendation.titleShort[' +
                        extremePerformance +
                        ']'
                    )
                  "
                  x-small
                ></v-btn>
                <v-btn
                  v-show="recommendationSelectBtnVisible(custom)"
                  color="primary"
                  v-html="
                    $t('sawCalc.recommendation.titleShort[' + custom + ']')
                  "
                  x-small
                ></v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :cols="recommendationCols"
              v-if="recommendationVisible(performance)"
              class="px-0 py-0"
              style="min-width: 200px;"
            >
              <Recommendation
                :recommendationIndex="performance"
                :key="recommendationKey"
              />
            </v-col>
            <v-col
              :cols="recommendationCols"
              v-if="recommendationVisible(highPerformance)"
              class="px-0 py-0"
              style="min-width: 200px;"
            >
              <Recommendation
                :recommendationIndex="highPerformance"
                :key="recommendationKey"
              />
            </v-col>
            <v-col
              :cols="recommendationCols"
              v-if="recommendationVisible(extremePerformance)"
              class="px-0 py-0"
              style="min-width: 200px;"
            >
              <Recommendation
                :recommendationIndex="extremePerformance"
                :key="recommendationKey"
              />
            </v-col>
            <v-col
              v-if="showCustom"
              class="px-0 py-0"
              style="min-width: 200px;"
            >
              <RecommendationCustom :recommendationIndex="custom"
            /></v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="pt-2">
      <v-col class="pa-0 ma-0 primary" style="height: 2px;"></v-col>
    </v-row>

    <v-overlay :value="sawCalcLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import InputUOM from "@/components/public/SawCalc/Inputs/InputUOM";
import InputMachine from "@/components/public/SawCalc/Inputs/InputMachine";
import InputMaterial from "@/components/public/SawCalc/Inputs/InputMaterial";
import InputDimensions from "@/components/public/SawCalc/Inputs/InputDimensions";
import InputVising from "@/components/public/SawCalc/Inputs/InputVising";
import InputConditions from "@/components/public/SawCalc/Inputs/InputConditions";
import InputCurrency from "@/components/public/SawCalc/Inputs/InputCurrency";
import Recommendation from "@/components/public/SawCalc/Recommendation";
import RecommendationCustom from "@/components/public/SawCalc/RecommendationCustom";
import MachineSelector from "@/components/public/SawCalc/Selectors/MachineSelector";
import MaterialSelector from "@/components/public/SawCalc/Selectors/MaterialSelector";
import DimensionsSelector from "@/components/public/SawCalc/Selectors/DimensionsSelector";
import VisingSelector from "@/components/public/SawCalc/Selectors/VisingSelector";
import ConditionsSelector from "@/components/public/SawCalc/Selectors/ConditionsSelector";

import { mapGetters } from "vuex";
import router from "@/router";

import { config } from "@/config/ConfigSettings";
import { api } from "@/helpers/ApiCalls";
import { recommendations } from "@/constants/Recommendations";

export default {
  name: "SawCalc",

  components: {
    InputUOM,
    InputMachine,
    InputMaterial,
    InputDimensions,
    InputVising,
    InputConditions,
    InputCurrency,
    Recommendation,
    RecommendationCustom,
    MachineSelector,
    MaterialSelector,
    DimensionsSelector,
    VisingSelector,
    ConditionsSelector,
  },

  data() {
    return {
      reportEnabled: false,
    };
  },

  computed: {
    ...mapGetters([
      "input",
      "metric",
      "selectedRecommendation",
      "selectedRecommendationForCustom",
      "manufacturers",
      "machines",
      "userMachines",
      "bladeWidthImperialList",
      "bladeWidthMetricList",
      "bladeThicknessImperialList",
      "bladeThicknessMetricList",
      "bladeLengthFtList",
      "bladeLengthInList",
      "bladeLengthFrList",
      "bladeLengthMetricList",
      "materialStandards",
      "unsCategories",
      "unsAlloyGroups",
      "subCategories",
      "materials",
      "selectMaterials",
      "userMaterials",
      "axiosSource",
      "sawCalcLoading",
      "settingsLoaded",
      "user",
      "recommendationUpdated",
      "recommendations",
      "recommendationKey",
    ]),
    desktopView() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return false;
        case "md":
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    singleView() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
        case "md":
        case "lg":
        case "xl":
        default:
          return false;
      }
    },
    inputsCols() {
      return this.desktopView ? 2 : 0;
    },
    recommendationsCols() {
      return this.desktopView ? 10 : 12;
    },
    recommendationCols() {
      // return this.desktopView ? 4 : 12;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
        case "md":
        case "lg":
        case "xl":
        default:
          return 4;
      }
    },
    showCustom() {
      if (!this.singleView) {
        return this.selectedRecommendationForCustom != null;
      } else {
        return this.selectedRecommendation == recommendations.Custom;
      }
    },
    performance() {
      return recommendations.Performance;
    },
    highPerformance() {
      return recommendations.HighPerformance;
    },
    extremePerformance() {
      return recommendations.ExtremePerformance;
    },
    custom() {
      return recommendations.Custom;
    },
  },

  methods: {
    recommendationChanged(index) {
      this.$store.dispatch("selectRecommendation", index);
    },
    recommendationVisible(index) {
      if (this.singleView) {
        return this.selectedRecommendation == index;
      } else {
        if (this.selectedRecommendationForCustom == null) {
          return true;
        } else {
          return this.selectedRecommendationForCustom == index;
        }
      }
    },
    recommendationSelectBtnVisible(index) {
      if (index != 3) {
        if (this.selectedRecommendationForCustom == null) {
          return true;
        } else {
          return this.selectedRecommendationForCustom == index;
        }
      } else {
        return this.selectedRecommendationForCustom != null;
      }
    },
    dbSync(dbSyncSeconds) {
      if (this.settingsLoaded) {
        this.$store.dispatch("syncRequired", dbSyncSeconds).then((response) => {
          if (response) {
            this.$store.dispatch("resetSawCalcReady");
            this.$store.dispatch("getSawCalcData").then((response) => {
              const error = api.responseError(response);

              if (error) {
                if (api.authenticationError(error.code)) {
                  setTimeout(function() {
                    router.push({ name: "Home" }).catch(() => {});
                  }, api.authErrorRedirectTimeout);
                }
              } else {
                this.$store.dispatch("checkInputs");
              }
            });
          } else {
            this.$store.dispatch("checkInputs");
          }
        });
      } else {
        setTimeout(() => this.dbSync(config.dbSyncSeconds()), 100);
      }
    },
    clear() {
      this.$store.dispatch("resetInputs", null);
      this.$store.dispatch("resetOutputs");
    },
    getRecommendationReport() {
      this.$store.dispatch("getRecommendationReport").then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    emailRecommendationReport() {
      this.$store.dispatch("emailRecommendationReport").then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    refresh() {
      this.clear();
      this.$store.dispatch("resetSawCalcReady");
      this.$store.dispatch("getSawCalcData").then((response) => {
        const error = api.responseError(response);

        if (error) {
          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      });
    },
    updateControls() {
      this.reportEnabled = false;

      this.recommendations.forEach((item) => {
        if (item != null && item.cutTime > 0) {
          this.reportEnabled = true;
          return;
        }
      });
    },
  },

  watch: {
    recommendationUpdated() {
      this.updateControls();
    },
  },

  mounted() {
    this.dbSync(config.dbSyncSeconds());
    this.updateControls();
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  max-height: 30px;
  min-height: 23px;
}
.button-small {
  width: 146px;
}
.button-large {
  width: 184px;
}
.controls-small-max {
  max-width: 146px;
}
.controls-large-max {
  max-width: 184px;
  margin-left: 20px;
}
</style>
