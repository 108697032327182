<template>
  <v-container fluid class="py-0">
    <v-row justify="center" @click="showSelector">
      <v-col class="pa-0">
        <v-card flat 
          :class="{
            'card-input': selectedRecommendationForCustom == null,
            'card-disabled': selectedRecommendationForCustom != null,
          }">
          <v-card-text class="pa-2">
            <v-container fluid class="pa-0">
              <v-row>
                <v-col
                  class="px-2 py-0"
                  :class="{
                    'title-ready': inputConditions.lubrication != null,
                    'title-not-ready': inputConditions.lubrication == null,
                    'card-disabled': selectedRecommendationForCustom != null,
                  }"
                  ><h4>{{ $t("sawCalc.inputConditions.title") }}</h4></v-col
                >
              </v-row>
              <v-row v-if="conditionsReady">
                <v-col class="px-2 pt-1 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.inputConditions.lubrication')"
                /></v-col>
              </v-row>
              <v-row v-if="conditionsReady">
                <v-col 
                  class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="getLubrication(inputConditions.lubrication)" />
                </v-col>
              </v-row>
              <v-row v-if="conditionsReady">
                <v-col class="px-2 pt-1 pb-0 card-text-caption"
                  ><span v-html="$t('sawCalc.inputConditions.pinching')"
                /></v-col>
              </v-row>
              <v-row v-if="conditionsReady">
                <v-col 
                  class="px-4 py-0 card-text-description"
                  :class="{
                    'card-disabled': selectedRecommendationForCustom != null,
                  }">
                  <span v-html="getPinching(inputConditions.pinching)" />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="px-2 pt-1 pb-0 card-text-error"
                  ><span v-html="$t('sawCalc.inputConditions.errorNotLoaded')"
                /></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InputCondition",

  computed: {
    ...mapGetters([
      "inputConditions",
      "conditionsReady",
      "selectedRecommendationForCustom",
    ]),
  },

  methods: {
    showSelector() {
      if (
        this.conditionsReady &&
        this.selectedRecommendationForCustom == null
      ) {
        this.$store.dispatch("showConditionsSelectorDialog", true);
      }
    },
    getLubrication(lubrication) {
      if (lubrication == null) {
        return "";
      }

      if (lubrication == 0) {
        return this.$t('sawCalc.inputConditions.lubricationFloodLabel')
      } else if (lubrication == 1) {
        return this.$t('sawCalc.inputConditions.lubricationMistLabel')
      } else if (lubrication == 2) {
        return this.$t('sawCalc.inputConditions.lubricationDryLabel')
      } else {
        return "";
      }
    },
    getPinching(pinching) {
      if (pinching == null) {
        return "";
      }

      if (pinching == 0) {
        return this.$t('sawCalc.inputConditions.noLabel')
      } else if (pinching == 1) {
        return this.$t('sawCalc.inputConditions.yesLabel')
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-input {
  cursor: pointer;
}
.card-disabled {
  color: map-get($theme, footer-link-color);
  cursor: not-allowed;
}
</style>