<template>
  <div>
    <v-card outlined>
      <v-form ref="unknownForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-overlay :absolute="true" :value="machineOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>
              <v-col v-if="user" cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  autofocus
                  :label="$t('sawCalc.inputMachine.manufacturerLabel')"
                  :disabled="unknownOverlay"
                  v-model="unknown.manufacturerName"
                  :rules="unknownInputRules"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="user" cols="12" sm="6" class="pl-2 pr-7 pb-2">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.modelLabel')"
                  :disabled="unknownOverlay"
                  v-model="unknown.model"
                  :rules="unknownInputRules"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="px-2 pr-7 pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeWidthMetricLabel')"
                  :items="bladeWidthMetricList"
                  :disabled="unknownOverlay"
                  @change="bladeWidthMetricChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="5" class="px-2 pr-7  pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeThicknessMetricLabel')"
                  :items="bladeThicknessMetricList"
                  :disabled="unknownOverlay"
                  @change="bladeThicknessMetricChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="px-2 pr-7 pt-0">
                <v-autocomplete
                  :label="$t('sawCalc.inputMachine.bladeLengthMetricLabel')"
                  :items="bladeLengthMetricList"
                  :disabled="unknownOverlay"
                  @change="bladeLengthMetricChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.machineOrientationLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="unknown.machineOrientation"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="unknownOverlay">
                    <span>
                      {{
                        $t(
                          "sawCalc.inputMachine.machineOrientationHorizontalLabel"
                        )
                      }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="unknownOverlay">
                    <span>
                      {{
                        $t(
                          "sawCalc.inputMachine.machineOrientationVerticalLabel"
                        )
                      }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.scissorPivotLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="unknown.scissorPivot"
                  mandatory
                  dense
                >
                  <v-btn
                    color="primary"
                    :disabled="!scissorPivotEnabled || unknownOverlay"
                  >
                    <span>
                      {{ $t("sawCalc.inputMachine.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!scissorPivotEnabled || unknownOverlay"
                  >
                    <span>
                      {{ $t("sawCalc.inputMachine.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="px-2 pt-0">
                <span class="card-text-caption">
                  {{ $t("sawCalc.inputMachine.cantedHeadLabel") }}
                </span>
              </v-col>
              <v-col cols="12" sm="9" class="px-2 pt-0">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="unknown.cantedHead"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="unknownOverlay">
                    <span>
                      {{ $t("sawCalc.inputMachine.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="unknownOverlay">
                    <span>
                      {{ $t("sawCalc.inputMachine.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" class="px-2 pt-0">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.bandSpeedMinMetricLabel')"
                  :disabled="unknownOverlay"
                  v-model="unknown.bandSpeedMinMetric"
                  :rules="numberInputRules"
                  type="number"
                  @change="bandSpeedMinMetricChanged"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5" class="px-2 pt-0">
                <v-text-field
                  :label="$t('sawCalc.inputMachine.bandSpeedMaxMetricLabel')"
                  :disabled="unknownOverlay"
                  v-model="unknown.bandSpeedMaxMetric"
                  :rules="numberInputRules"
                  type="number"
                  @change="bandSpeedMaxMetricChanged"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="colsCount" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.clearButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!unknownDirty || unknownOverlay"
                  @click="resetUnknownForm()"
                ></v-btn>
              </v-col>
              <v-col
                v-if="user"
                cols="12"
                :sm="colsCount"
                class="px-1 pt-0"
                align="right"
              >
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.addButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!unknownValid || unknownOverlay"
                  @click="addUnknownMachine()"
                ></v-btn>
              </v-col>
              <v-col cols="12" :sm="colsCount" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('sawCalc.inputMachine.setMachine')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="!unknownValid || unknownOverlay"
                  @click="setUnknownMachine(unknown)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { calculations } from "@/helpers/Calculations";

export default {
  name: "UnknownMetric",

  components: {},

  props: {
    bladeWidthMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeWidthImperialList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeThicknessMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeThicknessImperialList: {
      type: Array,
      required: true,
      default: () => [],
    },
    bladeLengthMetricList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      machineOverlay: false,
      unknown: {
        manufacturerName: "",
        model: "",
        bladeWidthMetric: null,
        bladeThicknessMetric: null,
        bladeLengthMetric: null,
        machineOrientation: 0,
        scissorPivot: null,
        cantedHead: 0,
        bandSpeedMinMetric: "",
        bandSpeedMaxMetric: "",
      },
      unknownDefault: {
        manufacturerName: "",
        model: "",
        bladeWidthMetric: null,
        bladeThicknessMetric: null,
        bladeLengthMetric: null,
        machineOrientation: 0,
        scissorPivot: null,
        cantedHead: 0,
        bandSpeedMinMetric: "",
        bandSpeedMaxMetric: "",
      },
      unknownOverlay: false,
      unknownInputRules: [
        (v) =>
          (!!v && v.length > 0) || this.$t("sawCalc.inputMachine.inputRules"),
      ],
      numberInputRules: [
        (v) =>
          (!!v && v > 0) || this.$t("sawCalc.inputMachine.inputRules"),
      ],
    };
  },

  computed: {
    ...mapGetters(["inputUOM", "machine", "user", "resetInputs"]),
    unknownValid() {
      if (!this.unknown) {
        return false;
      }

      const bladeWidth = this.unknown.bladeWidthMetric;
      const bladeThickness = this.unknown.bladeThicknessMetric;
      const bladeLength = this.unknown.bladeLengthMetric;
      const bandSpeedMin = this.unknown.bandSpeedMinMetric;
      const bandSpeedMax = this.unknown.bandSpeedMaxMetric;

      let valid =
        this.unknown &&
        bladeWidth &&
        bladeThickness &&
        bladeLength &&
        bandSpeedMin &&
        bandSpeedMax;

      if (this.user) {
        valid = this.unknown.manufacturerName && this.unknown.model && valid;
      }

      return valid;
    },
    unknownDirty() {
      return (
        JSON.stringify(this.unknown) !== JSON.stringify(this.unknownDefault)
      );
    },
    scissorPivotEnabled() {
      return this.unknown.machineOrientation == 0;
    },
    colsCount() {
      if (this.user) {
        return 4;
      } else {
        return 6;
      }
    },
  },

  methods: {
    bladeWidthMetricChanged(item) {
      if (item == undefined) {
        this.unknown.bladeWidthMetric = null;
        return;
      }

      this.unknown.bladeWidthMetric = item;
      
      const machine = {
        bladeWidthMetric: item,
      }

      const bladeWidth = calculations.getBladeWidth(machine, true, this.bladeWidthImperialList, this.bladeWidthMetricList);
      this.unknown.bladeWidthIn = bladeWidth.in;
      this.unknown.bladeWidthFr = bladeWidth.fr;
    },
    bladeThicknessMetricChanged(item) {
      if (item == undefined) {
        this.unknown.bladeThicknessMetric = null;
        return;
      }

      this.unknown.bladeThicknessMetric = item;
      
      const machine = {
        bladeThicknessMetric: item,
      }

      const bladeThickness = calculations.getBladeThickness(machine, true, this.bladeThicknessImperialList, this.bladeThicknessMetricList);
      this.unknown.bladeThicknessImperial = bladeThickness.in;
    },
    bladeLengthMetricChanged(item) {
      if (item == undefined) {
        this.unknown.bladeLengthMetric = null;
        return;
      }

      this.unknown.bladeLengthMetric = item;

      const machine = {
        bladeLengthMetric: item,
      }

      const bladeLength = calculations.getBladeLength(machine, true)
      this.unknown.bladeLengthFt = bladeLength.ft;
      this.unknown.bladeLengthIn = bladeLength.in;
      this.unknown.bladeLengthFr = bladeLength.fr;
    },
    bandSpeedMinMetricChanged(item) {
      this.unknown.bandSpeedMinMetric = parseInt(item)
      this.unknown.bandSpeedMinImperial = parseInt(calculations.mpmToFpm(this.unknown.bandSpeedMinMetric))
    },
    bandSpeedMaxMetricChanged(item) {
      this.unknown.bandSpeedMaxMetric = parseInt(item)
      this.unknown.bandSpeedMaxImperial = parseInt(calculations.mpmToFpm(this.unknown.bandSpeedMaxMetric))
    },
    resetUnknownForm() {
      this.$store.dispatch("setMachine", null);
      this.$store.dispatch("setInputMachine", null);

      this.$refs.unknownForm.reset();
      this.setUnknownFormDefaults();
    },
    setUnknownFormDefaults() {
      this.unknown = JSON.parse(JSON.stringify(this.unknownDefault));
    },
    addUnknownMachine() {
      this.unknownOverlay = true;

      this.$store
        .dispatch("addUnknownMachine", {
          machine: this.unknown,
          uom: this.inputUOM,
        })
        .then(
          (response) => {
            this.unknownOverlay = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };
            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.unknownOverlay = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }
            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };
            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    setUnknownMachine(machine) {
      if (!machine) {
        return;
      }

      let manufacturerName = this.unknown.manufacturerName;
      if (!manufacturerName) {
        manufacturerName = "Unknown";
      }

      let model = this.unknown.model;
      if (!model) {
        model = "Unknown";
      }

      this.$store.dispatch("setInputMachine", machine);

      const notification = {
        show: true,
        result: true,
        message: `${this.$t("sawCalc.inputMachine.machine")}: ${
          machine.manufacturerName
        }/${machine.model} ${this.$t("sawCalc.inputMachine.set")}`,
      };

      this.$store.dispatch("showSnackbar", notification);
      this.close();
    },
    close() {
      this.$store.dispatch("showMachineSelectorDialog", false);
      this.$store.dispatch("checkInputs");
    },
  },

  watch: {
    resetInputs() {
      if (this.$refs.unknownForm) {
        this.$refs.unknownForm.reset();
      }
    }
  },

  mounted() {
    this.setUnknownFormDefaults();
  },
};
</script>
